.mobile_mpp {
  background: url('/media/export/images/gradient_top_border_sm.png') repeat-x;
}

.clr {
  clear: both;
}

a {
  outline: 0;
}

.mpp_filter {
  padding: 15px;
  background-color: #eff1f1;
  border: solid 1px #d8d7d7;
  border-width: 1px 0;
  font-size: 15px;
  background: url('/media/export/images/plus_icon.png') no-repeat 285px 15px;
}

.mpp_filter.filter_down {
  background: url('/media/export/images/minus_icon.png') no-repeat 285px 15px;
}

.mpp_filter a {
  color: #000;
  text-decoration: none;
  display: block;
  width: 100%;
}

.mpp_filters form {
  padding: 20px 0;
}

.mpp_filters input[type='submit'] {
  font-size: 12px;
  margin: 12px 0 0;
  background-color: $color-cl-green-2;
  border: 0 none;
  color: #ffffff;
  display: inline-block;
  font-weight: bold;
  height: 24px;
  letter-spacing: 1px;
  line-height: 27px;
  padding: 0 10px;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
  @if $cr19 == true {
    background-color: $cr19-bg-black;
    color: $cr19-text-white;
  }
}

.lrg_img {
  display: none;
}

a.button {
  width: 100%;
  margin: 12px 0 0;
  height: 45px;
  font-size: 18px;
  line-height: 44px;
}

a.button.select_shade.active {
  background-color: #bebebe;
}

.button-wrapper span {
  display: none;
  width: 100%;
  height: 45px;
  padding: 20px 0 0;
}

.button-wrapper.loading span {
  display: block;
}

.button-wrapper.loading a {
  display: none;
}

.product-info {
  width: 185px;
  float: #{$rdirection};
}

.spp-product {
  padding: 25px 15px;
  border-bottom: solid 1px #ccc;
}

.product-list {
  padding: 0;
  margin: 0;
}

.reviews {
  color: #000;
}
/* toggle full width */

.product-list.full_width .product-thumb {
  width: 100%;
  display: block;
  text-align: center;
}

.product-list.full_width .product-info {
  width: 100%;
}

.product-list.full_width .lrg_img {
  display: block;
  margin: 0 auto;
}

.product-list.full_width .sm_img {
  display: none;
}

.product-list.full_width {
  .product-thumb_img {
    text-align: center;
  }
}

.BVRRRatingSummaryLinkWriteFirst {
  float: none;
  position: relative;
  top: 20px;
}
/* swatches */
.shaded {
  width: 100%;
}

.product-addl-info {
  text-align: #{$rdirection};
  clear: both;
  width: 100%;
  .smoosh {
    float: #{$rdirection};
    margin-top: 0;
    margin-#{$rdirection}: 20px;
    max-width: 160px;
  }
  .smoosh.trio-smoosh {
    div {
      display: inline;
    }
    img {
      width: 40px;
      height: 48px;
      margin-bottom: -1px;
      margin-top: -1px;
      margin-#{$rdirection}: -1px;
      margin-#{$ldirection}: 0px;
    }
  }
  .smoosh.quad-smoosh {
    div {
      display: inline;
    }
  }
}

.swatches {
  float: #{$ldirection};
  max-width: 125px;
  padding: 20px 0 0;
  width: auto;
}

.spp_view {
  .swatches {
    padding: 0 0 10px;
  }
}

.btn-favorite {
  height: 20px;
  width: 25px;
  display: inline-block;
  float: #{$rdirection};
  @include swap_direction(margin, 5px 0 0 5px);
  background: url($img_base_path + '/btn-favorite-sprite.png') no-repeat 0px 0px;
  &:hover,
  &.active {
    background-position: 0px -24px;
  }
}

.full_width .spp-product .btn-favorite {
  @include swap_direction(margin, 18px 15px 0 5px);
}

.swatches li {
  float: #{$ldirection};
}

.swatches li .swatch {
  display: block;
  width: 40px;
  height: 40px;
  float: #{$ldirection};
  @include swap_direction(margin, 0 0 1px 1px);
  border: solid 2px #fff;
}

.swatches li .swatch:hover,
.swatches li .swatch.active {
  border-color: #515254;
}

.swatches li .swatch .multi-2 {
  width: 100%;
  height: 50%;
  float: #{$ldirection};
}

.swatches li .swatch .multi-4 {
  width: 50%;
  height: 50%;
  float: #{$ldirection};
}

.swatch_image {
  float: #{$rdirection};
  @include swap_direction(padding, 20px 0 0 0);
  max-width: 160px;
}

[data-productid='PROD35510'] .sbHolder.sized {
  background-image: none;
}

[data-productid='PROD35510'] .sized .sbOptions li {
  background-image: none;
}

.sbHolder.sized {
  background: url('/media/export/images/ddml_pc.png') #f1f4f5 no-repeat -128px -6px;
  &.Jar {
    background: url('/media/export/images/jar_pc.png') no-repeat -126px -8px;
  }
  &.Square {
    background: url('/media/export/images/square_bottle_pc.png') no-repeat -127px -6px;
  }
  &.Tube {
    background: url('/media/export/images/tube_pc.png') no-repeat -128px -6px;
  }
  &.Tall {
    background: url('/media/export/images/tall_bottle_pc.png') no-repeat -126px -5px;
  }
  &.Fragrance {
    background: url('/media/export/images/fragrance_pc.png') no-repeat -126px -6px;
  }
}

.sized .sbOptions li {
  background: url('/media/export/images/ddml_pc.png') no-repeat -128px 6px;
  &.Jar {
    background: url('/media/export/images/jar_pc.png') no-repeat -126px 1px;
  }
  &.Square {
    background: url('/media/export/images/square_bottle_pc.png') no-repeat -127px 6px;
  }
  &.Tube {
    background: url('/media/export/images/tube_pc.png') no-repeat -128px 6px;
  }
  &.Tall {
    background: url('/media/export/images/tall_bottle_pc.png') no-repeat -126px 6px;
  }
  &.Fragrance {
    background: url('/media/export/images/fragrance_pc.png') no-repeat -126px 4px;
  }
}

.desc {
  padding: 20px 15px 0;
  h3 {
    color: #111;
    font-size: 22px;
    padding: 0;
  }
  p {
    color: #343434;
  }
  span.more {
    display: none;
  }
  .view_more {
    .more {
      display: inline;
    }
    a.more {
      display: none;
    }
  }
}

.how_to_use {
  table {
    width: auto;
  }
  h3 {
    color: #111;
    font-size: 22px;
    padding: 0 15px;
  }
  ul {
    @include swap_direction(padding, 0 15px 0 30px);
    list-style: initial;
    li {
      line-height: 1.5em;
      color: #343434;
    }
  }
}

.spp_product_status {
  color: #ff3b89;
  padding-top: 0;
  clear: both;
  .coming_soon_stock_msg a {
    clear: left;
    float: #{$ldirection};
    margin-bottom: 3px;
  }
  .coming_soon_stock_msg a.btn-comingsoon {
    color: $color-cl-green-2;
    background-color: #fff;
    border: 1px solid $color-cl-green-2;
    @if $cr19 == true {
      background-color: $color-white;
      border-color: $cr19-border-light;
      color: $cr19-text;
    }
  }
  .coming_soon_stock_msg a.email-me {
    color: #000;
    text-decoration: none;
  }
}

.field-item-set-type,
.module-may-we-suggest {
  display: none;
}
/*
.module-how-use { padding: 0; }
.module-how-use h2 { margin-top: 0; }
*/
.module-how-use {
  display: none;
}
/* Bug 170825 - Clinique DrupalGem Redesign/Mobile/Mobile Tweaks from Brand Review 1/16 + one tweak to PC */
.device-mobile .product.spp-product .mpp_product h3 {
  color: #1f1f1f;
  font-size: 23px;
  padding: 15px;
}

.device-mobile .product.spp-product .reviews {
  border-bottom: 1px solid #dcdcdc;
  padding-bottom: 15px;
  margin-bottom: 10px;
}

.device-mobile .product.spp-product .mpp_product {
  position: relative;
  p.price {
    color: #111;
    font-size: 18px;
    margin-#{$ldirection}: 15px;
    & ~ .price {
      display: none; // only show the first one
    }
  }
}

.device-mobile {
  .product.spp-product {
    .mpp_product {
      .product-info.price2 {
        .strike-thru-price {
          color: #dbdbdb;
          display: inline;
          font-size: 18px;
          line-height: 1.2;
          margin-#{$ldirection}: 15px;
          text-decoration: line-through;
          & ~ .strike-thru-price {
            display: none; // only show the first one
          }
        }
        .price {
          color: $color-discount-price;
          display: block;
          & ~ .price {
            display: none; // only show the first one
          }
        }
      }
    }
  }
}

.device-mobile .product.spp-product .shaded {
  padding: 0 15px;
}

.device-mobile .product.spp-product .shaded .sbHolder {
  margin-bottom: 20px;
}

.device-mobile .product.spp-product .shaded .button-wrapper {
  margin: 0 0 20px;
}

.device-mobile .product.spp-product .shaded .button-wrapper a.button {
  clear: both;
  margin: 0;
}

.device-mobile .spp-product {
  overflow: visible;
  padding: 0;
  .icons {
    color: #ccc;
    /* display: inline; */
    padding-#{$ldirection}: 10px;
    [class^='icon-']:hover {
      color: #666;
      cursor: pointer;
    }
    .icon-heart:hover {
      color: #fe6cb5;
    }
  }
  .icon-message {
    border: 1px solid #000000;
    display: none;
    float: #{$ldirection};
    margin: 0;
    padding: 5px;
    text-transform: uppercase;
    width: 315px;
    > span {
      display: none;
    }
  }
  .icon-message.active {
    display: inline-block;
  }
  .icon-heart-message.favorites-add-success {
    .favorites-added-signedIn {
      display: none;
    }
    .favorites-added-signedOut {
      display: block;
    }
    .favorites-exists {
      display: none;
    }
  }
  .icon-heart-message.favorites-add-exists {
    .favorites-exists {
      display: block;
    }
  }
}

.device-mobile .full_width .how_to_use {
  padding: 0 15px;
}

.device-mobile .full_width .how_to_use {
  &.spp-product {
    padding-bottom: 10px;
  }
}

.device-mobile .full_width .how_to_use h3 {
  padding: 0;
}

.sbHolder .shade_icon {
  width: 44px;
  height: 44px;
  float: #{$ldirection};
}

.sbHolder .shade_icon .multi-2 {
  width: 50%;
  height: 100%;
}

.sbHolder .shade_icon .multi-4 {
  width: 50%;
  height: 50%;
}

.sbHolder .sbOptions li.multi-shade .shade_icon {
  width: 44px;
  height: 44px;
  float: #{$ldirection};
}

.sbHolder .sbOptions li.multi-shade .shade_icon .multi-2 {
  width: 50%;
  height: 100%;
}

.sbHolder .sbOptions li.multi-shade .shade_icon .multi-4 {
  width: 50%;
  height: 50%;
}

.sbHolder .sbOptions li.multi-shade a {
  padding-#{$ldirection}: 55px;
}

.sbHolder .sbOptions li a {
  @include swap_direction(padding, 15px 0 15px 12px);
}

.product-list {
  .replenishment {
    background: transparent url('/media/images/global/nav/top/calendar-icon.png') no-repeat scroll 0 0;
    color: $color-notice-pink;
    font-size: 13px;
    height: 25px;
    margin: 0;
    padding-#{$ldirection}: 35px;
    text-align: #{$ldirection};
    width: auto;
    cursor: pointer;
    clear: both;
  }
}
/* Added to Favorites PopUp */

.device-mobile #foreground-node {
  @include swap_direction(padding, 20px 25px 20px 20px);
  .btn-favorite {
    background: url('/sites/clinique/themes/cl_base/img/btn-favorite-sprite.png') no-repeat scroll 0 -24px rgba(0, 0, 0, 0);
    display: inline-block;
    float: #{$ldirection};
    height: 20px;
    margin: 0;
    width: 25px;
  }
  &.replenishment-info .close-container {
    position: absolute;
  }
  .close-container {
    #{$rdirection}: 5px;
    top: 3px;
    position: absolute;
    a.close-link {
      width: 22px;
      height: 21px;
      background-position: 5px 0;
      background-size: 18px;
    }
  }
}
/* Insert swoosh inside ul.swatches to be able to wrap the swatches squares around the swoosh */
.swatches li.extra-swoosh {
  float: right;
  clear: right;
}

.swatches li.extra-swoosh .smoosh.single-smoosh {
  width: 150px;
  display: block;
}
/* SPP POPUP */

.order_arrival #order_arrival_info {
  margin-bottom: 15px;
}

.order_arrival #order_arrival_info a {
  color: #000;
}

.order_arrival_details_overlay {
  display: none;
}

.edd_popover {
  height: 380px !important;
  width: 100% !important;
  z-index: 10000 !important;
}

.order_arrival .order_arrival_details {
  text-align: #{$ldirection};
  @include swap_direction(margin, 28px 0 0 6px);
}

.order_arrival_details .postal_code input {
  height: 45px;
  width: 145px;
  float: #{$ldirection};
  padding-#{$ldirection}: 10px;
}

.order_arrival_details .postal_code {
  @include swap_direction(margin, 4px 0 14px 0);
  height: 45px;
}

.order_arrival_details p.title {
  font-size: 15px;
  text-transform: uppercase;
  line-height: 11px;
  color: #5dba98;
  font-weight: bold;
  margin-bottom: 11px;
}

.order_arrival_details .postal_code .apply {
  display: block;
  padding: 0;
  float: #{$ldirection};
  @include swap_direction(margin, 0 0 0 8px);
  border: 1px solid #5dba98;
  background: none;
  color: #5dba98;
  width: 102px;
  text-transform: uppercase;
  height: 45px;
  line-height: 45px;
}

.order_arrival_details .edd_info {
  @include swap_direction(margin, 20px 0 0 0);
}

.order_arrival_details #order_arrival_info {
  cursor: pointer;
  text-decoration: underline;
}

.order_delivery_info .header {
  display: none;
  height: 25px;
  border-bottom: 1px solid #e9e9e9;
}

.order_arrival_details .order_delivery_info {
  margin-bottom: 10px;
}

.order_arrival_details .order_delivery_info .header div {
  font-weight: bold;
  font-size: 12px;
  color: #000;
}

.order_arrival_details .shipment_type {
  float: #{$rdirection};
  text-align: #{$rdirection};
  width: 45%;
  @include swap_direction(margin, 0 0 0 4px);
}

.order_arrival_details .arrival_date {
  float: #{$ldirection};
  width: 53%;
}

.order_arrival_details .row {
  border-bottom: 1px solid #e9e9e9;
  height: 30px;
  line-height: 30px;
}

.order_arrival_details p {
  font-size: 14px;
}

.order_arrival_details .invalid_zipcode {
  color: #b50404;
}

.order_delivery_info .ship_us_territory,
.order_delivery_info .ship_us_mil_pobox,
.order_delivery_info.valid_zipcode .ship_us_std,
.order_delivery_info .ship_us_std_exact {
  display: none;
}

.order_delivery_info.valid_zipcode .ship_us_std_exact {
  display: block;
}

.order_arrival_details {
  color: #000;
  font-size: 13px;
}

.edd_popover.empty_zipcode {
  height: 600px !important;
}

.edd_popover.zipcode {
  height: 525px !important;
}

#overlay-background {
  width: 100% !important;
}
/* END OF SPP POPUP */

/* Specific prod ids overrides:
 *
 * Right now there is now way to be able to hid the shades and swooshes on products that doesn't uses them on mobile.
 * The mobile template uses the {{#shaded}}...{{/shaded}} .NET variable which is too generic.
 * Any SPP using the "SPP Override" in CMS can't have the mobile template enabled. If the mobile template is enabled
 * it'll force the mobile template to use the PC template.
 */

/* Page URL: /product/12516/36159/Skin-Care/Cleansing-Brush/Clinique-Sonic-System-Purifying-Cleansing-Patterned-Brush */
.product.spp-product[data-productid='PROD36159'] .product-addl-info {
  display: none;
}
