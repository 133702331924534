.nav-promo {
  .offer-promo-carousel-v1 {
    .cycle-slideshow {
      .headline {
        span {
          font-size: 12px;
          color: rgb(18, 18, 18) !important;
        }
      }
    }
  }
}

.content_container {
  .my-account-block-mobile {
    background-color: #ebebeb;
    a.button {
      margin-bottom: 12px;
      background-color: $color-white;
      img {
        height: 20px;
        position: relative;
        top: 4px;
        margin-right: 5px;
      }
    }
  }
  .breadcrumb-wrapper {
    ul.breadcrumbs {
      padding: 15px;
      li {
        display: inline;
        font-size: 14px;
        color: rgb(31, 31, 31);
        a {
          color: rgb(31, 31, 31);
        }
      }
    }
  }
  .mobile_mpp {
    h2.mpp_cat_header {
      font-size: 20px;
      color: #000000;
      padding: 15px;
      margin: 0;
      line-height: 25px;
      border-bottom: 1px solid #cccccc;
    }
    ul.mpp-sort-by {
      text-align: right;
      margin: 12px 0;
      li {
        display: inline;
        margin: 0 5px;
        font-size: 14px;
        color: #000000;
        a.sort {
          color: rgb(186, 185, 185);
          font-weight: normal;
          &.active {
            color: #000000;
            font-weight: bold;
          }
        }
      }
    }
    ul#mpp-product-grid {
      li.mpp-product {
        .product-info {
          h3.product-name {
            a {
              color: #000000;
              font-size: 20px;
              line-height: 25px;
            }
          }
          .price {
            .formatted_price {
              font-size: 15px;
              margin: 10px 0;
              color: rgb(146, 146, 146);
            }
            &.price2 {
              .formatted_price {
                color: $color-radical-red;
              }
            }
          }
          .reviews {
            color: #000000;
            font-size: 14px;
            a.btn-favorite {
              float: left;
              position: relative;
            }
          }
          .button-view-detail {
            a.btn-shopnow {
              border: 2px solid #5dba98;
              font-weight: bold;
            }
          }
          .not_shaded_sized {
            .button-wrapper {
              .loading-status {
                margin-top: 5px;
              }
            }
          }
        }
        .shaded {
          .product-addl-info {
            ul.swatches {
              max-width: 164px;
              padding: 20px 0 10px;
            }
          }
          .button-wrapper {
            a {
              font-family: 'Hiragino kaku Gothic Std';
              font-weight: bold;
            }
          }
          .sbHolder.sized {
            margin-bottom: 20px;
          }
        }
        .spp_product_status {
          a {
            border: 2px solid #5dba98;
            font-weight: bold;
          }
        }
      }
    }
  }
  ul.spp_view {
    li.spp-product {
      .mpp_product {
        h3.product-name {
          font-size: 24px;
          line-height: 34px;
        }
        .desc,
        .product-info {
          p,
          div {
            color: #000000;
          }
          .price {
            float: left;
            padding: 10px 0 0 15px;
            p {
              margin: 0;
              font-weight: bold;
              font-size: 13px;
              letter-spacing: -0.05em;
            }
          }
          .favorite-button {
            float: right;
            padding: 10px 15px 0 0;
            a.btn-favorite {
              float: left;
              margin: 0;
            }
            span.favorite-text {
              float: left;
              margin-top: 4px;
              font-size: 10px;
            }
          }
        }
      }
    }
    li.how_to_use {
      h3 {
        font-weight: bold;
      }
      border-bottom: 1px solid #dcdcdc;
      padding-bottom: 15px;
    }
  }
  .mobile_mpp,
  .spp_view {
    .button-wrapper {
      a {
        font-weight: bold;
      }
    }
  }
  .goes_great_with.spp-extra {
    .module-may-we-suggest {
      display: block;
      .outer-wrap {
        padding: 0;
        h2 {
          background-color: #f1f4f5;
          font-size: 26px;
          font-weight: bold;
          padding: 10px;
          color: #000000;
        }
        ul.product-grid {
          li.box {
            display: block;
            clear: left;
            .product {
              a {
                width: 100%;
                background: url('/media/export/cms/mobile/prev-next-arrows.png') no-repeat scroll 97% -13px transparent;
                display: block;
                .product-image {
                  width: 75px;
                  float: left;
                  vertical-align: middle;
                }
                h3 {
                  margin: 37.5px 0 !important;
                  width: 75%;
                  display: inline-block;
                  float: left;
                  vertical-align: middle;
                }
              }
            }
          }
        }
      }
    }
  }
}

.spp_reviews {
  .BVDIHeader.BVDI_QTHeader {
    background-color: #f1f4f5;
    .BVDITitle {
      font-weight: bold;
    }
  }
}

body.section-esearch {
  #search {
    #refinements {
      display: none !important;
    }
  }
}

body.section-store-locator {
  .locations_form_panel {
    .zip_section_container {
      .state_container,
      .city_container {
        .sbHolder {
          margin-bottom: 20px;
          .sbSelector {
            margin: 0 30px 0 10px;
          }
          .sbOptions {
            overflow: auto !important;
            max-height: auto !important;
            li {
              padding: 0px;
            }
          }
        }
      }
    }
    .geo_search {
      img {
        top: 5px;
      }
    }
  }
  .locations_table_panel {
    .store_info {
      margin-bottom: 20px;
      .store_name {
        margin-bottom: 10px;
      }
      .store_address {
        color: #000;
        margin-bottom: 10px;
      }
    }
  }
  .store-locator-mobile-v1 {
    .map_container {
      margin: 0 10px !important;
      .scroll-overlay {
        z-index: 0;
      }
      .gm-style {
        .gm-style-iw {
          top: 10px !important;
          padding: 15px;
        }
        .location_info {
          padding: 0 !important;
        }
      }
    }
  }
  .button {
    line-height: 32px !important;
  }
}

.springtrend_image_v1 {
  background-color: #f2f2f2;
  overflow: hidden;
  .outer-wrap {
    padding: 0;
    h3 {
      color: #121212;
      font-size: 30px;
      margin: 15px 0;
      text-align: center;
    }
    .spring_inner_block {
      img.spring_image {
        float: left;
        width: 50%;
      }
    }
  }
}

.device-mobile {
  #top {
    height: auto;
    #header {
      width: 100%;
      .block-template-site-logo-responsive-v1 {
        height: 60px;
        width: 40%;
        a {
          text-align: center;
        }
        @media (min-width: 736px) and (orientation: landscape) {
          .site-logo-responsive-v1 {
            padding: 0px !important;
          }
        }
        @media (min-width: 414px) and (orientation: portrait) {
          .site-logo-responsive-v1 {
            padding: 0px !important;
          }
        }
        @media only screen and (min-device-height: 279px) and (min-device-width: 640px) and (orientation: landscape) and (resolution: 288dpi) {
          .site-logo-responsive-v1 a img {
            margin-left: 0px !important;
            margin-right: 0px !important;
          }
        }
        @media only screen and (min-device-height: 559px) and (min-device-width: 360px) and (orientation: portrait) and (resolution: 288dpi) {
          .site-logo-responsive-v1 a img {
            margin-left: 0px !important;
            margin-right: 0px !important;
          }
        }
      }
      .btn_navigation {
        background: url('/media/export/cms/mobile/btn_navigation_jp.png') no-repeat center 0;
        opacity: 0.9;
        width: 16%;
        vertical-align: top;
      }
      .block-template-search-mobile-v1 {
        width: 14%;
        vertical-align: top;
        .search-icon img {
          margin: -7px auto 0;
        }
      }
      .block-template-gnav-utility-button-mobile-v1 {
        width: 15%;
        vertical-align: top;
        .gnav-utility-button-v1 {
          text-align: center;
        }
      }
      .block-template-gnav-cart-v1 {
        float: left;
        width: 14%;
        vertical-align: top;
        .gnav-drop-down-v1 {
          margin: 0 auto;
        }
      }
    }
    .nav-promo {
      height: 81px;
      -webkit-box-shadow: 0px 0px 0px 0px #dedede;
      box-shadow: 0px 0px 0px 0px #dedede;
      .block-template-offer-promo-mobile-v1 {
        -webkit-box-shadow: -2px 8px 7px -5px #dedede;
        box-shadow: -2px 8px 7px -5px #dedede;
        position: relative;
        background-color: $color-white;
      }
    }
  }
  &.front {
    #top {
      height: 149px;
      #header {
        height: 68px;
        .block-template-site-logo-responsive-v1 {
          height: 60px;
        }
      }
    }
  }
  &.elc-user-state-logged-in {
    .menu-mlid-296ae432-3c2f-11e3-814d-000c29cc69ea {
      display: none !important;
    }
    .menu-mlid-79c0ac58-3c31-11e3-814d-000c29cc69ea {
      display: inline !important;
    }
  }
  &.elc-user-state-anonymous {
    .menu-mlid-296ae432-3c2f-11e3-814d-000c29cc69ea {
      display: inline !important;
    }
    .menu-mlid-79c0ac58-3c31-11e3-814d-000c29cc69ea {
      display: none !important;
    }
  }
  .cart-confirm-wrapper {
    .cart-overlay-products {
      .shade_info {
        .shade_thumb {
          width: 20px;
          height: 20px;
          display: inline-block;
          vertical-align: middle;
          margin-right: 10px;
        }
      }
    }
  }
  .home_gnav {
    li {
      float: left;
      list-style-type: none;
      padding: 15px;
      text-align: center;
      vertical-align: middle;
      width: 33.3%;
      font-size: 12px;
      border-right: 1px solid #e6e6e6;
      background-color: #5eb997;
      a {
        color: #fff;
        font-size: 14px;
        font-weight: 600;
      }
    }
    @media #{$portrait} {
      li {
        line-height: 63px;
        &.last {
          line-height: inherit;
        }
      }
      overflow: hidden;
      height: 95px;
    }
  }
  .product-content-carousel-v1 {
    .flex-direction-nav {
      display: block;
    }
  }
  #footer {
    .footer-bottom {
      padding-bottom: 20px;
      .inner {
        .block {
          display: inline-block;
          div {
            display: inline-block;
            &.field-mobile-menu {
              display: block;
              ul.menu {
                display: inline-block;
                overflow: hidden;
                li {
                  border-right: 1px solid #231f20;
                  border-left: none;
                  letter-spacing: -1.6px;
                  float: none;
                  display: inline-block;
                  a {
                    font-size: 10px;
                  }
                  &.last {
                    border: none;
                  }
                  @media only screen and (max-device-width: 480px) and (min-device-width: 320px) and (orientation: portrait) {
                    &.item-3,
                    &.item-5 {
                      padding-left: 10px !important;
                    }
                    &.item-4,
                    &.item-7 {
                      padding: 0 10px 0 0 !important;
                    }
                    &.item-10 {
                      padding: 0 10px 0 0 !important;
                    }
                  }
                  @media only screen and (max-device-width: 568px) and (orientation: landscape) {
                    &.item-6 {
                      padding: 0 10px 0 0 !important;
                    }
                  }
                  &.item-10 {
                    border-right: none !important;
                  }
                  &.item-11 {
                    clear: both;
                    letter-spacing: 0px !important;
                    border-right: none !important;
                  }
                  &.last {
                    letter-spacing: 0px;
                  }
                }
              }
            }
          }
        }
      }
    }
    .footer-top {
      .footer-top-mobile-v1 {
        padding: 0 !important;
        .newsletter-signup {
          h2 {
            margin-top: 10px;
          }
          .newsletter-sub-heading {
            margin-bottom: 10px;
            p {
              margin: 0px;
              color: $color-black;
              font-size: 16px;
            }
          }
        }
        .social-links {
          overflow: hidden;
          margin: 20px auto !important;
        }
        .top_link {
          text-align: right;
          a.back-to-top {
            position: relative !important;
            bottom: 0em !important;
            padding-left: 35px;
            background: url(/media/export/cms/arrow_upper.png) 10px 50% / 18px no-repeat rgba(235, 235, 235, 0.8);
            display: inline-block !important;
          }
        }
      }
    }
  }
  .gnav-drop-down-v1 {
    position: relative;
    .template-icon-processed {
      span {
        left: 10px;
        position: absolute;
        top: 39px;
      }
    }
  }
}

.vertical-carousel-navigation-v1 .module {
  .flexslider {
    h3 {
      display: block !important;
      text-align: center;
    }
    .flex-control-nav {
      position: static;
    }
  }
}

body.section-3-step {
  .accordionItem {
    &.three-step-shopbystep-mobile-v1 {
      .product-info {
        h3.product-name {
          min-height: 120px !important;
        }
      }
    }
    &.three-step-row-mobile-v1 {
      .cta-button-wrapper {
        a.button {
          padding: 0px 10px 12px !important;
        }
      }
    }
  }
}

.section-mens {
  #header {
    .block-template-gnav-cart-v1 {
      .node.clearfix {
        overflow: visible;
      }
    }
  }
}

input[type='checkbox'][name='ACCEPTED_PRIVACY_POLICY'],
input[type='checkbox'][name='PC_EMAIL_PROMOTIONS'] {
  opacity: 0.9;
}

.spp_product_status {
  .temp_out_of_stock_msg {
    a.button.secondary.btn-large {
      background-color: transparent;
      color: #5dba98;
      border: 1px solid #5dba98;
    }
  }
}

.spp_product_status {
  .temp_out_of_stock_msg {
    a.button.secondary.btn-large:hover {
      background-color: #f3faf7;
    }
  }
}

.offer-promo {
  .link-based-offer-ref-v1 {
    .link-based-offer-mobile-v1 {
      .subheading h2 {
        display: none;
      }
    }
  }
}

#viewcart {
  #main.multi {
    .offer-status-lbo span {
      margin-left: 14px;
    }
  }
}

body#viewcart.device-mobile {
  #top {
    position: relative;
  }
}

.device-mobile {
  #wrapper,
  #content,
  #main {
    clear: both;
  }
  .product-list {
    .replenishment {
      line-height: 28px;
    }
  }
}

.eh-thewink-hp-redesign-menu-formatter-v1 {
  .thewink-mobile-header {
    height: auto !important;
    width: 100% !important;
    .hidden {
      height: auto !important;
      width: 18% !important;
    }
  }
}
