.mobile_mpp {
  background: url('/media/export/images/gradient_top_border_sm.png') repeat-x;
}

.mobile_mpp > h2 {
  color: #000000;
  margin-#{$ldirection}: 15px;
  padding-top: 20px;
}

.clr {
  clear: both;
}

a {
  outline: 0;
}

.mpp_filter {
  padding: 15px;
  background-color: #eff1f1;
  border: solid 1px #d8d7d7;
  border-width: 1px 0;
  font-size: 15px;
  background: url('/media/export/images/plus_icon.png') no-repeat 285px 15px;
}

.mpp_filter.filter_down {
  background: url('/media/export/images/minus_icon.png') no-repeat 285px 15px;
}

.mpp_filter a {
  color: #000;
  text-decoration: none;
  display: block;
  width: 100%;
}

.mpp_filters form {
  padding: 20px 0;
}

.mpp_filters input[type='submit'] {
  font-size: 12px;
  margin: 12px 0 0;
  background-color: $color-cl-green-2;
  border: 0 none;
  color: #ffffff;
  display: inline-block;
  font-weight: bold;
  height: 24px;
  letter-spacing: 1px;
  line-height: 27px;
  padding: 0 10px;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
  @if $cr19 == true {
    background-color: $cr19-bg-black;
    color: $cr19-text-white;
  }
}

.mpp_toggle_view {
  text-align: #{$rdirection};
  padding: 15px 15px 0;
  width: 100%;
  color: #000;
}

.mpp_toggle_view a {
  float: #{$rdirection};
  margin-#{$ldirection}: 10px;
  display: block;
  width: 39px;
  height: 16px;
  background: url('/media/export/images/rows_icon.png');
}

.mpp_toggle_view a.toggle_on {
  background: url('/media/export/images/stacked_icon.png');
}

.product-thumb .badge--small {
  img {
    position: absolute;
    &.sm_img {
      z-index: 2;
    }
    #{$ldirection}: 50%;
    &.lrg_img {
      margin-#{$ldirection}: -90px;
    }
    &.sml_img {
      margin-#{$ldirection}: -58px;
    }
  }
}

.lrg_img {
  display: none;
}

a.button {
  font-size: 18px;
  height: auto;
  line-height: 40px;
  margin: 12px 0 0;
  padding: 0;
  width: 100%;
}

a.button.select_shade.active {
  background-color: #bebebe;
}

.button-wrapper span {
  display: none;
  width: 100%;
  height: 45px;
  padding: 20px 0 0;
}

.button-wrapper.loading span {
  display: block;
}

.button-wrapper.loading a {
  display: none;
}

.product-info {
  width: 185px;
  float: #{$rdirection};
}

.mpp-product {
  padding: 25px 15px;
  border-bottom: solid 1px #ccc;
  overflow: visible;
  position: relative;
  img.sm_img {
    z-index: 1;
    position: absolute;
    #{$ldirection}: 0;
  }
  .sbHolder {
    .sbOptions {
      z-index: 2;
    }
  }
}

.product-list {
  padding: 0;
  margin: 0;
}

.device-mobile .price {
  margin: 0;
}

.mobile_mpp {
  ul {
    li {
      .product-info.price2 {
        .strike-thru-price {
          color: #dbdbdb;
          display: inline;
          font-size: 14px;
          line-height: 1.2;
          text-decoration: line-through;
          & ~ .strike-thru-price {
            display: none; // only show the first one
          }
        }
        .price {
          color: $color-discount-price;
          display: block;
        }
      }
    }
  }
}

.reviews {
  color: #000;
}

.mobile_mpp .reviews {
  position: relative;
}

.mobile_mpp .reviews .btn-favorite {
  bottom: 0;
  margin: 0;
  position: absolute;
  #{$rdirection}: 0;
}

.unit select {
  display: none;
}
/* toggle full width */

.product-list.full_width .product-thumb {
  width: 100%;
  display: block;
  text-align: center;
}

.product-list.full_width .product-info {
  width: 100%;
}

.product-list.full_width .lrg_img {
  display: block;
  margin: 0 auto;
}

.product-list.full_width .sm_img {
  display: none;
}
/* swatches */
.shaded {
  width: 100%;
}

.product-addl-info {
  text-align: #{$rdirection};
  clear: both;
  width: 100%;
}

.swatches {
  @include swap_direction(padding, 0 0 0 20px);
  width: 147px;
  float: #{$ldirection};
}

.swatches li {
  float: #{$ldirection};
}

.swatches li .swatch {
  display: block;
  width: 40px;
  height: 40px;
  float: #{$ldirection};
  @include swap_direction(margin, 0 0 1px 1px);
  border: solid 2px #fff;
}

.swatches li .swatch:hover,
.swatches li .swatch.active {
  border-color: #515254;
}

.swatches li .swatch .multi-2 {
  width: 50%;
  height: 100%;
  float: #{$ldirection};
}
/* setting specific px for width. ios tries to divide them funky */
.swatches li .swatch .multi-3 {
  width: 12px;
  height: 100%;
  float: #{$ldirection};
}

.swatches li .swatch .multi-4 {
  width: 50%;
  height: 50%;
  float: #{$ldirection};
}

.swatch_image {
  float: #{$ldirection};
  @include swap_direction(padding, 20px 0 0 15px);
}

.sbHolder.sized {
  background: url('/media/export/images/size_icon-1.png') #f1f4f5 no-repeat 10px 5px;
}

.sized .sbOptions li {
  background: url('/media/export/images/size_icon-1.png') no-repeat 10px 5px;
}

.sbHolder.sized.single-size .sbToggle {
  display: none;
}

.sbHolder.sized.single-size .sbOptions {
  display: none !important;
}
/* MPP-specific smoosh styling */

.mobile_mpp {
  .product-addl-info {
    .smoosh {
      img {
        width: 73px;
        height: 74px;
      }
    }
  }
}
/* Filters navigation */
.mobile_mpp .mpp-filter {
  position: relative;
}

.mobile_mpp .mpp-filter ul.menu .depth-1,
.mobile_mpp .mpp-filter .menu-container.mpp-filter-content.depth-2 {
  display: none;
  margin-#{$ldirection}: 100%;
  width: 100%;
}

.mobile_mpp .mpp-filter ul.menu .depth-1 {
  margin-top: 0;
}

.mobile_mpp .mpp-filter .menu-container.mpp-filter-content.depth-2 {
  margin-top: 0;
}
/* Filter menu items */
.mobile_mpp .mpp-filter ul.menu,
.mobile_mpp .mpp-filter ol,
.mobile_mpp .mpp-filter ul,
.mobile_mpp .mpp-filter .mpp-wrapper ul.menu li.filter-main {
  margin: 0;
  padding: 0;
}

.mobile_mpp .mpp-filter ul li {
  border-top: 1px solid #fff;
}

.mobile_mpp .mpp-filter ul li,
.mobile_mpp .mpp-filter ul li h2 {
  cursor: pointer;
  font-size: 18px;
  margin: 0;
  padding: 5px 15px;
}

.mobile_mpp .mpp-filter ul li h2 {
  height: 50px;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.mobile_mpp .mpp-filter ul li.filter-main h2 {
  padding-#{$rdirection}: 5px;
}

.mobile_mpp .mpp-filter ul li.filter-depth-1-main h2 {
  border-bottom: 1px solid #ffffff;
}

.mobile_mpp .mpp-filter ul li.filter-main h2 a,
.mobile_mpp .mpp-filter ul li.filter-depth-1-main h2 a,
.mobile_mpp .mpp-filter ul li.filter-depth-2-main h2 a {
  color: #1f1f21;
  height: 100%;
  padding-#{$ldirection}: 0;
  padding-top: 3px;
  position: absolute;
  text-transform: capitalize;
  width: 100%;
}

.mobile_mpp .mpp-filter ul li.filter-depth-2-main h2.back > a {
  width: 60%;
}

.mobile_mpp .mpp-filter ul li h2 a:hover,
.mobile_mpp .mpp-filter ul li.filter-depth-1-main h2 a:hover,
.mobile_mpp .mpp-filter ul li.filter-depth-2-main h2 a:hover {
  text-decoration: none;
}

.mobile_mpp .mpp-filter ul li.back h2,
.mobile_mpp .mpp-filter ul li.inactive {
  background: none repeat scroll 0 0 #d9d9d9;
  border-top: 1px solid #ffffff;
}

.mobile_mpp .mpp-filter ul li.filter-main > h2 {
  background: none repeat scroll 0 0 #ffffff;
  border-bottom: 1px solid #d9d9d9;
  border-top: 1px solid #d9d9d9;
}

.mobile_mpp .mpp-filter ul li.first.filter-main h2 span.plus {
  background: url($template_base_path + '/power_couples_row_v1/sprite-powercouples.png') no-repeat scroll right -215px transparent;
  display: inline;
  float: #{$rdirection};
  height: 40px;
  width: 40px;
}

.mobile_mpp .mpp-filter ul li.back {
  margin: 0;
  padding: 0;
}

.mobile_mpp .mpp-filter ul li.back h2 {
  border-bottom: 1px solid #ffffff;
  margin: 0;
  @include swap_direction(padding, 5px 15px 5px 0);
}

.mobile_mpp .mpp-filter ul li h2.back a span.icon,
.mobile_mpp .mpp-filter ul li h2 span.icon-next {
  background-color: transparent;
  background-image: url('/media/export/cms/mobile/nav_arrows_sprite_mobile.png');
  background-repeat: no-repeat;
  height: 37px;
  width: 27px;
}

.mobile_mpp .mpp-filter ul li h2.back a span.icon {
  background-position: 5px 8px;
  #{$ldirection}: 0;
  position: absolute;
  top: -2px;
}

.mobile_mpp .mpp-filter ul li.filter-depth-2-main h2.back a span.icon {
  display: inline-block;
  #{$ldirection}: -15px;
  margin-#{$rdirection}: 0;
}

.mobile_mpp .mpp-filter ul li.filter-depth-2-main h2.back a span.icon + span {
  margin-#{$ldirection}: 18px;
}

.mobile_mpp .mpp-filter ul li h2 span.icon-next {
  background-position: -18px 8px;
  display: inline-block;
  @include swap_direction(margin, 0 0 0 80px);
  position: absolute;
  #{$rdirection}: 0;
}

.mobile_mpp .mpp-filter ul li.filter-item,
.mobile_mpp .mpp-filter ul li.filter-depth-1-main,
.mobile_mpp .mpp-filter ul li.filter-depth-2-main {
  background: none repeat scroll 0 0 #ebebeb;
  display: block;
  margin: 0;
  position: relative;
  vertical-align: top;
  width: 100%;
}

.mobile_mpp .mpp-filter ul li:first-child {
  background: none repeat scroll 0 0 #dadada;
}

.mobile_mpp .mpp-filter ul li.filter-item {
  padding: 10px 15px;
}

.mobile_mpp .mpp-filter ul li.filter-item.active {
  background: none repeat scroll 0 0 #dfdfdf;
}

.mobile_mpp .mpp-filter ul li.filter-depth-1-main,
.mobile_mpp .mpp-filter ul li.filter-depth-2-main {
  border: medium none;
  padding: 0;
}

.mobile_mpp .mpp-filter ul li.inactive {
  display: none;
}

.mobile_mpp .mpp-filter ul li.hidden {
  display: none !important;
}

.mobile_mpp .mpp-filter ul li.first.back h2.back.hidden {
  display: block;
}

.mobile_mpp .mpp-filter ul li.filter-item a {
  color: #bbbbbb;
}

.mobile_mpp .mpp-filter ul li.filter-item a.active {
  color: #1f1f1f;
}

.mobile_mpp .mpp-filter ul li.filter-item a.button {
  background: none repeat scroll 0 0 #bebebe;
  color: #ffffff;
  font-size: 13px;
  height: 25px;
  line-height: 18px;
  @include swap_direction(margin, 5px 0 5px 20px);
  padding: 3px 0;
  position: absolute;
  text-transform: uppercase;
  #{$rdirection}: 15px;
  top: 7px;
  width: 27%;
}

.mobile_mpp .mpp-filter ul li.filter-depth-2-main h2.back a.button.active {
  background: none repeat scroll 0 0 $color-cl-green-2;
  z-index: 100;
  @if $cr19 == true {
    background-color: $cr19-bg-black;
  }
}

.mobile_mpp .mpp-filter ul li.filter-item a .close-filter {
  background: url('/sites/clinique/themes/cl_base/img/mpp/mpp-remove-filter-x.png') no-repeat scroll 0 0 transparent;
  color: black;
  display: none;
  font-size: 12px;
  font-style: normal;
  height: 11px;
  line-height: 13px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  #{$rdirection}: 9px;
  text-align: center;
  text-indent: -9999px;
  top: 15px;
  width: 12px;
}

.mobile_mpp .mpp-filter ul li.filter-item a.active span {
  display: inline-block;
}

.mobile_mpp .spp_product_status {
  padding-top: 10px;
}
/* Relocated "ADD TO BAG" button to be on top of the size/shade select */
.device-mobile .product.mpp-product .shaded .button-wrapper {
  margin: 0 0 20px;
}

.device-mobile .product.mpp-product .shaded .button-wrapper a.button {
  clear: both;
  margin: 0;
}

.sbHolder .shade_icon {
  width: 44px;
  height: 44px;
  float: #{$ldirection};
}

.sbHolder .shade_icon .multi-2 {
  width: 50%;
  height: 100%;
}

.sbHolder .shade_icon .multi-3 {
  width: 33%;
  height: 100%;
}

.sbHolder .shade_icon .multi-4 {
  width: 50%;
  height: 50%;
}

.sbHolder .sbOptions li.multi-shade .shade_icon {
  width: 44px;
  height: 44px;
  float: #{$ldirection};
}

.sbHolder .sbOptions li.multi-shade .shade_icon .multi-2 {
  width: 50%;
  height: 100%;
}

.sbHolder .sbOptions li.multi-shade .shade_icon .multi-3 {
  width: 33%;
  height: 100%;
}

.sbHolder .sbOptions li.multi-shade .shade_icon .multi-4 {
  width: 50%;
  height: 50%;
}

.sbHolder .sbOptions li.multi-shade a {
  padding-#{$ldirection}: 55px;
}

.sbHolder .sbOptions li a {
  @include swap_direction(padding, 15px 0 15px 12px);
}

.mobile_mpp .sbHolder.sized.Tube {
  background-color: #f1f4f5;
}

.mobile_mpp .product-list .replenishment {
  padding-top: 15px;
  background-position: 0 15px;
  height: 40px;
}

.device-mobile .product-list .replenishment {
  color: #5dbab1;
  line-height: 13px;
  margin: 0 0 20px;
}
/* Division line & caret */
.device-mobile .mpp-box .shaded.border,
.sells-with--panel.border {
  border-top: 1px solid $color-light-grey;
  margin-top: 5px;
  overflow: visible;
  padding-top: 20px;
  position: relative;
}

.device-mobile .mpp-box .shaded .caret,
.sells-with--panel .caret {
  background: url('/media/export/images/qs-caret.png') no-repeat scroll 50% 0 rgba(0, 0, 0, 0);
  display: block;
  height: 12px;
  padding: 0;
  position: absolute;
  #{$rdirection}: 20px;
  top: -12px;
  width: 182px;
  z-index: 9000;
}
