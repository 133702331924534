/**
 * @file jp_mobile.scss
 *
 * \brief Pull all parts of the JP-MOBILE theme together into one file
 */

@import 'overrides/overrides';
@import '../../cl_base/scss/cl-base-mobile';
@import 'components/mobile';
@import 'sections/perlgem/mobile_all';
@import 'sections/_checkout_1pc.scss';
@import 'jp_mobile_fonts';
@import 'sections/power-review/power_reviews_mobile';
@import 'components/spp_product_layout';
@import 'components/_clinical_reality';
@import 'components/click_and_collect';
@import 'components/cms_mobile_styles';
@import 'components/shoppable-video';

// One Trust Cookie Styles.
@import '../../cl_base/scss/sections/one_trust_cookie/cookie_settings_ot';

@media only screen and (max-width: 320px) {
  .product-list {
    .mpp-product {
      .product-thumb {
        img:first-child {
          height: 135px;
          width: 117px;
        }
      }
    }
  }
}

// Password Strengthening
@import 'sections/account-password-strengthen';
//Appointment Booking local styles
@import 'sections/appointment_booking/appointment_booking_shared';
@import 'sections/appointment_booking/appointment_booking_mobile';
@import 'base/shared_all';
//Loyalty
@import '../../cl_base/scss/loyalty/loyalty_sign_in';
@import '../../cl_base/scss/loyalty/loyalty_global';
@import '../../cl_base/scss/loyalty/loyalty_checkout_confirmation';
@import 'sections/_loyalty';
// VTO Foundation
@import 'sections/foundation-vto';
