/* VARIABLES */

$ar_text_color: #1f1f1f;
$ar_label_color: #231f20;
/* END VARIABLES */

#replenish.device-mobile {
  #main {
    section {
      padding: 0;
    }
    header {
      padding: 1em 0 2em;
      h1 {
        font-size: 34px;
        margin: 0 10px;
      }
      .subtitle {
        margin: 0 10px 15px;
        width: auto;
        label {
          cursor: default;
        }
      }
    }
  }
  .products {
    float: none;
    .product {
      border-bottom: 1px solid #d5d5d5;
      height: auto;
      overflow: hidden;
      padding: 5% 3%;
      width: 100%;
      &:first-child {
        border-top: 1px solid #d5d5d5;
      }
      .product-image {
        display: inline-block;
        overflow: hidden;
        @include swap_direction(padding, 0 10px 0 0);
        vertical-align: top;
        width: 38%;
        img {
          display: block;
          height: auto;
          width: 128px;
        }
      }
      .product-info {
        padding-bottom: 20px;
        padding-#{$ldirection}: 10px;
        width: 60%;
        .price {
          padding: 0;
        }
      }
      a.button {
        border-radius: 0;
      }
    }
  }
  &.device-mobile {
    .controls .cancel-link.control-btns {
      @include a-button; // @todo remove extend for 2019 Redesign
      background: $color-white;
      border: 1px solid $color-cl-green-2;
      color: $color-cl-green-2;
      @if $cr19 == true {
        background-color: $color-white;
        border-color: $cr19-border-light;
        color: $cr19-text;
      }
    }
    .auto-replenishment-items {
      .my-account-sku {
        border-bottom: 0;
        .quantity {
          display: none;
        }
        .price {
          width: 60%;
        }
        .last-order-date {
          float: #{$rdirection};
          width: 62%;
          margin: 0 0 10px;
          line-height: inherit;
          .view-order {
            width: 100%;
            margin-top: 5px;
            display: inline-block;
          }
          .order-number {
            display: inline-block;
            margin-top: 10px;
            width: 100%;
          }
        }
        .next-order-date {
          float: #{$rdirection};
          width: 62%;
          margin-bottom: 10px;
        }
        .frequency {
          width: 100%;
          height: auto;
          .auto-replenish-item {
            float: #{$rdirection};
            width: 62%;
            #refill_sku {
              width: 100%;
            }
            .rep-details {
              line-height: 2.1;
              height: 36px;
              width: 90%;
            }
          }
        }
      }
      .products .product {
        padding-#{$ldirection}: 0;
        padding-#{$rdirection}: 0;
        .product-info {
          padding-#{$ldirection}: 0;
          padding-bottom: 10px;
        }
      }
      products .product {
        .product-image {
          padding-#{$rdirection}: 15px;
        }
      }
    }
    #shipping-frequency {
      p {
        color: $ar_text_color;
      }
      strong {
        color: $ar_text_color;
      }
      .order-info {
        margin-top: 10px;
        p {
          margin: 0;
        }
        li {
          margin-bottom: 10px;
        }
      }
      .order-freq {
        p {
          margin: 0;
        }
        li {
          margin-bottom: 10px;
        }
      }
    }
    #shipping-address {
      p {
        color: $ar_text_color;
      }
      .shipping-address-container {
        fieldset.fs {
          margin: 0;
          padding: 0;
        }
        .address {
          .address_controls {
            .radio {
              margin-bottom: 10px;
            }
          }
          label {
            color: ar_label_color;
            font-size: 13px;
            font-weight: bold;
          }
        }
      }
    }
    #payment-method {
      .payment_methods_container {
        fieldset.fs {
          margin: 0;
          padding: 0;
        }
        .edit-controls {
          margin-bottom: 10px;
        }
        .choose_method {
          .payment_method {
            margin-top: 10px;
          }
        }
      }
    }
    #replenishment_update {
      p {
        color: $ar_text_color;
      }
      #shipping-address {
        .address_controls {
          float: #{$ldirection};
          width: 100%;
          padding-#{$rdirection}: 0;
        }
      }
      #payment-method {
        .payment_method_display {
          float: #{$ldirection};
          width: 100%;
          padding-#{$rdirection}: 0;
          margin-bottom: 10px;
        }
      }
      .payment_method {
        width: 100%;
      }
      .my-account-sku {
        border-bottom: 0;
        .quantity {
          display: none;
        }
        .price {
          width: 60%;
          vertical-align: top;
          float: #{$ldirection};
          margin-bottom: 5px;
        }
        .last-order-date {
          display: none;
          float: #{$rdirection};
          width: 62%;
          margin: 0 0 10px;
          line-height: inherit;
          .view-order {
            width: 100%;
            margin-top: 5px;
            display: inline-block;
          }
        }
        .next-order-date {
          float: #{$rdirection};
          width: 62%;
          margin-bottom: 10px;
        }
        .frequency {
          width: 100%;
          height: auto;
          .auto-replenish-item {
            display: none;
            float: #{$rdirection};
            width: 62%;
            #refill_sku {
              width: 100%;
            }
            .replenishment-info {
              @include swap_direction(margin, 0 0 10px 0);
            }
          }
        }
      }
      #item-details {
        color: $ar_text_color;
        .product-image {
          display: inline-block;
          overflow: hidden;
          @include swap_direction(padding, 0 10px 0 0);
          vertical-align: top;
          width: 38%;
          float: #{$ldirection};
          padding-#{$rdirection}: 15px;
          img {
            display: block;
            height: auto;
            width: 120px;
          }
        }
        .product-info {
          float: #{$ldirection};
          width: 60%;
          margin-bottom: 10px;
        }
      }
    }
  }
  .auto-replenishment-items .products {
    .product-header {
      display: none;
    }
  }
}
/* Products */
