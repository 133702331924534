/* Hide extra cart overlay */

#favorites.device-mobile div > div {
  &.overlay,
  &.cart-dialog.cart-popup {
    display: none !important;
  }
}

.my-account-favorites-mobile-v1 {
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: relative;
  .tabs {
    margin: 0;
    overflow: hidden;
    padding: 0;
    .share-favorites {
      border-bottom: 1px solid $color-light-grey;
      float: #{$rdirection};
      height: 40px;
      margin: 0;
      @include swap_direction(padding, 10px 10px 0 0);
      width: 100%;
      .share-button {
        background: url('/media/images/css/icons-share-buttons.png') no-repeat scroll 0 0 rgba(0, 0, 0, 0);
        float: #{$rdirection};
        height: 20px;
        @include swap_direction(margin, 0 0 0 20px);
        overflow: hidden;
        text-indent: -9999px;
      }
      .email {
        background-position: -35px 0;
        width: 25px;
      }
      .facebook {
        background-position: -68px 0;
        width: 11px;
      }
      .twitter {
        background-position: -86px 0;
        width: 19px;
      }
      .pin-it-button {
        background-position: -111px 0;
        width: 19px;
      }
    }
    .list .share-favorites {
      border: medium none;
      display: none;
    }
    .panel {
      border-top: 1px solid $color-white;
    }
    ul {
      margin: 0;
      overflow: hidden;
      padding: 0;
      &.tabs-nav li {
        background: url($img_base_path + '/bg-supercat-header.jpg') repeat-x scroll left bottom $color-white;
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$color-white', endColorstr='#F7F7F7');
        background: -webkit-gradient(linear, left top, left bottom, from($color-white), to(#f7f7f7));
        background: -moz-linear-gradient(top, $color-white, #f7f7f7);
        border: none;
        border-bottom: 1px solid $color-light-grey;
        display: inline;
        float: #{$ldirection};
        margin: 0;
        padding: 0 10px;
        text-align: center;
        width: 50%;
        &.active {
          background: $color-white;
          border: 1px solid $color-light-grey;
          border-bottom: 1px solid $color-white;
        }
        &.item-0.active {
          border-#{$ldirection}: none;
        }
        &.item-1.active {
          border-#{$rdirection}: none;
        }
        a {
          color: #121212;
          display: block;
          font-size: 18px;
          padding: 15px 0;
        }
        &.active a {
          padding: 15px 0 14px;
          &.active {
            padding: 15px 0 14px;
          }
        }
      }
      li.no_lists {
        display: none;
      }
    }
    .tabs-content {
      border-top: medium none;
      padding: 0;
      width: 100%;
    }
    &.no-favorites {
      #favorites-tab.tabs-content {
        border-bottom: 1px solid $color-dark-grey;
      }
      .wishlists-tab {
        background: none repeat scroll 0 0 $color-white;
        border-bottom: 1px solid $color-dark-grey;
        a {
          cursor: default;
          text-indent: -10000px;
        }
      }
    }
    .tabs-content .favorite-products {
      background: none repeat scroll 0 0 rgba(0, 0, 0, 0);
      padding: 10px 0 0;
      .favorite-actions {
        width: 58%;
        float: right;
        text-align: center;
        a {
          border-right: 0px;
        }
      }
    }
    .favorite {
      border-bottom: 1px solid $color-light-grey;
      border-top: medium none;
      float: none;
      height: auto;
      margin: 0;
      overflow: hidden;
      padding: 30px 0;
      width: 100%;
      &:first-child {
        padding-top: 10px;
      }
      &:last-child {
        padding-bottom: 25px;
      }
      .my-account-sku {
        margin: 0;
      }
    }
    .tabs-content {
      .product-image {
        display: inline-block;
        overflow: hidden;
        @include swap_direction(padding, 0 10px 0 0);
        vertical-align: top;
        width: 38%;
        img {
          width: 128px;
        }
      }
      .product-info {
        padding-#{$ldirection}: 10px;
        width: 60%;
        .product-name a {
          color: #121212;
        }
        .shades,
        .sizes {
          margin: 5px 0;
        }
        .shades .shade.active {
          .search-swatch-container {
            display: inline;
            float: #{$ldirection};
            height: 15px;
            @include swap_direction(margin, 0 10px 0 0);
            width: 15px;
            .single {
              height: 100%;
              width: 100%;
            }
          }
          .shade-name {
            background: none repeat scroll 0 0 rgba(0, 0, 0, 0);
            font-size: 12px;
            margin-bottom: 10px;
            padding-#{$ldirection}: 0;
          }
        }
        .sizes .size.active {
          background: none repeat scroll 0 0 rgba(0, 0, 0, 0);
          font-size: 12px;
          margin-bottom: 10px;
          padding-#{$ldirection}: 0;
          margin: 0;
        }
        .reviews {
          margin-top: 5px;
          img {
            display: block;
            float: #{$ldirection};
            height: 10px;
            margin-#{$rdirection}: 10px;
            margin-top: 3px;
            width: 55px;
          }
        }
      }
      .add-to-bag,
      .btn-shop-now {
        border-radius: 0;
        float: #{$rdirection};
        margin-#{$rdirection}: 10px;
        padding-top: 0;
        width: 55%;
      }
      .share-favorites .print {
        display: none;
      }
      .account-favorites-mylists {
        margin: 10px 0 0;
        header {
          display: none;
          width: 100%;
        }
        .lists {
          min-height: 0;
          overflow: hidden;
          .list {
            &.create-list {
              display: none;
            }
            border-bottom: 1px solid $color-light-grey;
            border-#{$ldirection}: medium none;
            border-radius: 0;
            border-#{$rdirection}: medium none;
            border-top: medium none;
            display: inline-block;
            height: auto;
            margin: 0;
            overflow: hidden;
            padding: 0;
            position: static;
            vertical-align: top;
            width: 100%;
            h3 {
              color: #121212;
              cursor: pointer;
              display: block;
              font-size: 22px;
              line-height: 22px;
              margin: 0;
              @include swap_direction(padding, 10px 5px 15px 15px);
              img {
                display: none;
              }
            }
            .list-content {
              border-bottom: 1px solid $color-light-grey;
              display: none;
              padding: 20px 10px;
              overflow: hidden;
              &.active {
                display: block;
              }
              .product-image,
              .product-info {
                display: inline;
                float: #{$ldirection};
                overflow: hidden;
              }
              .product-image {
                clear: both;
                width: 40%;
              }
              .product-info {
                width: 58%;
                a {
                  color: #121212;
                }
              }
              .product-image img {
                display: block;
                margin: 0 10px;
                width: 70%;
              }
            }
            .favorite-actions {
              display: none;
              float: #{$rdirection};
              margin: 0;
              position: static;
              width: 33%;
              a {
                border-#{$rdirection}: medium none;
                margin: 0;
                padding: 0;
              }
            }
          }
        }
      }
    }
    .account-favorites-mylists .lists-msg {
      border-bottom: 1px solid $color-light-grey;
      color: $color-light-grey;
      padding: 20px 10px;
    }
    &.no-favorites {
      padding: 0;
    }
    .no-favorites {
      background: none repeat scroll 0 0 $color-white;
      padding: 0 0 10px;
      .no-favorites-text {
        float: none;
        font-size: 18px;
        padding: 0;
        width: 100%;
        p a:hover {
          text-decoration: none;
        }
      }
      .no-favorites-image {
        display: none;
      }
      .icon-heart:before {
        color: $color-btn-pink;
      }
    }
    &.no-favorites ul.tabs-nav li,
    ul.tabs-nav li.single {
      background: $color-white;
      border: none;
      padding: 0;
      width: auto;
      a {
        color: $color-cl-green-2;
        cursor: default;
        font-size: 34px;
        padding-#{$ldirection}: 10px;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}
/* Tabs content */

/* My lists */

/* No favorites */
