#pr-review-display {
  .p-w-r {
    .pr-review-display {
      .pr-rd-main-header {
        .pr-rd-review-header-contents {
          &:before {
            content: '絞り込み: ';
          }
        }
        .pr-multiselect {
          .pr-caret-icon {
            top: 22px;
          }
        }
        .pr-rd-sort {
          background: url(/media/images/stars/down-arrow.png) no-repeat scroll 94% 3px transparent;
        }
      }
      .pr-rd-sort-group {
        &:before {
          content: '並び替え: ';
        }
      }
      .pr-review {
        .pr-rd-footer {
          .pr-helpful-yes {
            .pr-helpful-count {
              &:before {
                content: 'はい';
              }
            }
          }
          .pr-helpful-no {
            .pr-helpful-count {
              &:before {
                content: 'いいえ';
              }
            }
          }
          .pr-rd-flag-review-container {
            clear: both;
          }
        }
        .pr-rd-description {
          .pr-rd-right {
            .pr-rd-reviewer-details {
              .pr-rd-author-location,
              .pr-rd-author-nickname {
                .pr-rd-bold {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
    .pr-multiselect {
      .pr-multiselect-button {
        @include swap_direction(padding, 0px 14px 0 0);
        @include swap_direction(margin, 5px 2px 0 0);
      }
      .pr-multiselect-options {
        span {
          font-size: 12px;
        }
      }
    }
  }
}

#pr-write {
  .p-w-r {
    .pr-war {
      .pr-footer {
        .pr-subscript {
          line-height: 1.3;
        }
        .pr-accessible-btn {
          @include swap_direction(padding, 4% 2%);
        }
      }
    }
  }
}

.elc-reviews {
  .tabs-nav {
    li {
      &:after {
        display: none;
      }
      &:last-child {
        display: none;
      }
    }
  }
}

/* Font Impementation */
#pr-write,
#pr-review-display,
.spp_page_wrap,
.spp_reviews,
#pr-reviewsnippet {
  .p-w-r {
    a {
      @extend %font-medium;
    }
    .pr-review-snapshot-header-intro {
      .pr-headline {
        @extend %font-medium;
      }
    }
    /* For Review Thank You page */
    .thank-you-page {
      h2.headline {
        @extend %font-medium;
      }
    }
  }
}

.mpp-grid,
.mpp-product {
  .p-w-r {
    .pr-category-snippet__total {
      @extend %font-medium;
    }
    .pr-snippet-stars-reco-inline {
      &.pr-snippet-minimal {
        .pr-snippet-read-and-write {
          @extend %font-medium;
        }
      }
    }
  }
}
