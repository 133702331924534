.device-mobile {
  .diagnostics-header {
    padding: 0;
    .outer-wrap {
      padding: 0 10px;
    }
    header {
      padding: 1em 0 1em;
      h1 {
        margin: 0;
      }
      .subtitle {
        color: #1f1f1f;
        font-size: 22px;
      }
    }
  }
  .blue-ocean-merch-window.grey-gradient {
    background: none repeat scroll 0 0 #ffffff;
    margin-bottom: 15px;
    .outer-wrap {
      padding: 0 10px;
    }
  }
  .foundation-finder {
    &.grey-gradient {
      background: none repeat scroll 0 0 #ffffff;
      border-top: 1px solid $color-light-grey;
    }
    .outer-wrap {
      padding: 0 10px;
      .no-results {
        width: 95%;
      }
    }
    padding: 0;
    .has-results {
      display: none;
      padding: 3em 0 5em;
    }
  }
}
