.device-mobile .store-locator-mobile-v1 {
  a:hover {
    text-decoration: none;
  }
  > * {
    margin-#{$ldirection}: 10px;
    margin-#{$rdirection}: 10px;
  }
  h2 {
    font-size: 36px;
    padding-top: 0;
    margin-top: 10px;
    color: #5dba98;
    text-transform: capitalize;
    font-size: 31px;
    font-weight: normal;
    @if $cr19 == true {
      color: $cr19-text-grey;
    }
  }
  h3 {
    color: #000;
    @include swap_direction(margin, 0 0 10px 10px);
  }
  h4.search_input_header,
  h4.locate_travel_location {
    color: #231f20;
    font-size: 12px;
    font-family: $helvetica-regular-font-family;
    text-transform: none;
    margin: 0;
    font-weight: bold;
  }
  h2.store_locator {
    margin-top: 50px;
  }
  .geo_search.button {
    background: none;
    border: 1px solid #e9e9e9;
    color: #666666;
    &:hover {
      background: none;
    }
  }
  .map-marker {
    background-image: url($template_base_path + '/store_locator_v1/result-pin.png');
    width: 29px;
    height: 44px;
    color: #fff;
    text-align: center;
    padding-top: 10px;
    font-weight: bold;
  }
  .search.button.search_by_location {
    margin-bottom: 0;
  }
  .map_container,
  .locations_table_panel {
    margin-#{$ldirection}: 0;
    margin-#{$rdirection}: 0;
  }
  .door_row {
    padding: 10px;
    border-bottom: 1px solid #d8d7d7;
  }
  .door_row:nth-last-child(1),
  .door_row:nth-child(10) {
    border: none;
  }
  .store_name {
    a {
      color: inherit;
      text-decoration: none;
    }
    &.actual_store_name a {
      font-weight: bold;
    }
  }
  .results-count {
    display: none;
  }
  .button {
    margin: 3px 0 15px;
    min-height: 34px;
    height: auto;
    border-radius: 0;
    width: 100%;
    line-height: 23px;
    &.more-results-button {
      margin-top: 0;
    }
  }
  .directions.button {
    border: 1px solid $color-cl-green-2;
    color: $color-cl-green-2;
    background-color: #fff;
    margin: 0;
    @if $cr19 == true {
      background-color: $color-white;
      border: 1px solid $cr19-border-light;
      color: $cr19-text;
    }
  }
  .event-image {
    float: #{$ldirection};
  }
  .button .geo_icon {
    position: relative;
    top: 3px;
    #{$ldirection}: -3px;
    margin-#{$rdirection}: 6px;
  }
  .search_input {
    background: white;
    border: 1px solid #dcddde;
    vertical-align: middle;
    color: #231f20;
    line-height: 35px;
    font-size: 15px;
    padding: 4px 0;
    border-radius: 0;
    @include swap_direction(margin, 8px 5px 12px 0);
    text-indent: 8px;
    width: 100%;
    height: 40px;
  }
  ul.error_messages li {
    color: $color-notice-pink;
    margin: 0 0 5px;
    display: inline-block;
    &.inactive {
      display: none;
    }
  }
  .location_info h1 {
    color: #5dba98;
    font-size: 14px;
    line-height: normal;
    margin: 0px 0px 10px;
    @if $cr19 == true {
      color: $cr19-text-grey;
    }
  }
  .doors_body {
    font-size: 14px;
    &.store_address {
      color: #808080;
      font-weight: normal;
      line-height: 21px;
    }
  }
  a.view-details {
    font-weight: bold;
  }
  .map_canvas {
    width: 100%;
    height: 320px;
  }
  .map_container {
    @extend .map_canvas;
    position: relative;
    .scroll-overlay {
      width: 55px;
      height: 320px;
      background: url('http://paulsebastianmusic.com/doubleprimetest/scroll-map-overlay.png')
        no-repeat right;
      background-size: cover;
      position: absolute;
      top: 0;
      #{$ldirection}: 0;
      z-index: 1000;
      opacity: 0.9;
    }
  }
  .search_container {
    border-bottom: 1px solid #dcddde;
    margin-bottom: 15px;
  }
  input.search_input {
    width: 100%;
    margin-bottom: 20px;
  }
  select.country,
  select.state,
  select.city {
    width: 100%;
    padding: 5px 13px;
    margin: 10px 0;
  }
  .locator_content {
    label {
      font-weight: normal;
    }
    .button {
      float: #{$rdirection};
    }
  }
  .results_header_wrapper {
    clear: both;
    padding-bottom: 5px;
    display: inline-block;
    width: 100%;
  }
  .event-details {
    margin: 10px 0;
  }
  .store_extra {
    margin: 10px 0 0;
    clear: both;
  }
  .results_header_wrapper {
    padding: 0;
    margin: 0;
  }
  .doors_results_header {
    color: #808080;
    padding: 10px;
    margin-bottom: 10px;
    .query {
      color: #000;
    }
  }
  input[type='text'].error,
  select.error {
    border: 1px solid #b50404;
    background-color: #fff;
    color: #b50404;
  }
}

[dir='rtl'] {
  .device-mobile {
    .store-locator-mobile-v1 {
      .map_container {
        .scroll-overlay {
          #{$rdirection}: 0;
        }
      }
    }
  }
}

.store-locator-mobile-popup-v1 {
  @extend .store-locator-mobile-v1;
  > * {
    margin-#{$ldirection}: 0;
    margin-#{$rdirection}: 0;
  }
  .event-popup-header {
    color: #000;
    margin: 12px 0 0;
    font-size: 24px;
  }
  h3 {
    font-size: 20px;
    color: #000;
    margin-bottom: 10px;
  }
  .event-popup-content {
    color: #000;
    font-size: 14px;
    @include swap_direction(margin, 10px 0 10px 10px);
    min-height: 125px;
  }
}

.locations_form_panel h3.store_locator {
  @include swap_direction(margin, 0 0 7px 0);
}
/* ADD EVENT TO CALENDAR */

.device-mobile .store-locator-mobile-popup-v1 .addthisevent-drop {
  background: none repeat scroll 0 0 #5dba98;
  border: 0 none;
  color: #ffffff !important;
  display: inline-block;
  font-family: $helvetica-bold-font-family;
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0px;
  line-height: 23px;
  padding: 4px 10px 3px;
  margin-bottom: 10px;
  text-align: center;
  text-transform: uppercase;
  @if $cr19 == true {
    background-color: $cr19-bg-black;
  }
}

.device-mobile .store-locator-mobile-popup-v1 .addthisevent-drop:hover {
  background: none repeat scroll 0 0 #44a07e;
  text-decoration: none;
}
/* END ADD EVENT TO CALENDAR */

/* APPOINTMENT BOOKING */

.device-mobile .store-locator-mobile-v1 .door_row .store-locator_book-appointment {
  background-color: $color-white;
  border: 1px solid #9e6f9c;
  color: #9e6f9c;
  display: block;
  font-size: 17px;
  line-height: 1.15em;
  margin: 7px 0 15px;
  padding: 5px 0 5px 5px;
  text-align: left;
  &:hover {
    color: #9e6f9c;
    background-color: $color-white;
  }
  span {
    background: white url('/media/export/cms/appointment_booking/icon-calendar.jpg') no-repeat
      scroll 0 0 / 81% 80%;
    float: left;
    height: 38px;
    margin-top: 4px;
    padding-bottom: 4px;
    padding-right: 5px;
    padding-top: 2px;
    width: 36px;
  }
}

.device-mobile {
  .location_info {
    .info_content {
      &.conten_popup_book_appointment {
        width: 180px;
      }
    }
  }
}

.location_info {
  .info_content {
    padding-bottom: 35px;
    padding-left: 15px;
  }
  .store-locator_book-appointment {
    background-color: #9e6f9c;
    border: 1px solid #9e6f9c;
    bottom: 0;
    color: white;
    display: block;
    font-size: 11px;
    left: 0;
    line-height: 1.15em;
    margin-top: 5px;
    padding: 5px 20px;
    position: absolute;
    text-align: left;
    text-transform: uppercase;
    width: 100%;
    &:hover {
      color: #fff;
    }
    span {
      background: #9e6f9c url('/media/export/cms/appointment_booking/icon-calendar-purple.jpg')
        no-repeat scroll 0 0;
      width: 28px;
      height: 23px;
      float: left;
    }
  }
}
/* END APPOINTMENT BOOKING */
