#viewcart {
  .enhanced-viewcart-page {
    .main_container {
      .multi#main {
        letter-spacing: normal;
        #samples-container.m_samples {
          border: 0;
          float: none;
          margin: 0;
        }
        #content {
          .offer-status {
            display: none;
          }
          #form--errors {
            li {
              font-size: 14px;
              padding-top: 14px;
            }
          }
          .sample-section-container {
            border: solid $cr19-border-light;
            border-width: 1px 0;
            margin: 0;
            padding: 0;
            .accordion-wrapper {
              &__header {
                color: $color-cl-light-black;
                font-size: 16px;
                letter-spacing: normal;
                line-height: 1;
                padding: 13px 16px;
                &.accordion-expand {
                  padding-bottom: 0;
                }
                &--icon {
                  #{$rdirection}: 16px;
                  top: 13px;
                }
              }
            }
          }
          .samples_content {
            #{$ldirection}: 100%;
            opacity: 0;
            padding: 0 16px 13px;
            position: absolute;
            width: 100%;
            .samples-title {
              &__text {
                color: $color-darkest-grey;
                font-family: $helvetica-regular-font-family;
                font-size: 14px;
                font-weight: normal;
                line-height: 1.4;
                padding: 10px 0 0;
              }
            }
            &.expanded {
              opacity: 1;
              position: static;
            }
            .sample-product {
              &__container {
                display: flex;
                flex-direction: column;
              }
              &__image {
                height: 87px;
                margin: 10px auto 0;
                width: 71px;
              }
              &__description {
                width: 100%;
              }
              &__image-wrapper {
                background: $cr19-bg-light;
                padding-bottom: 10px;
              }
              &-name,
              &-size {
                font-size: 13px;
                font-weight: normal;
                letter-spacing: 0;
                text-align: left;
              }
              &-name {
                color: $pick_x_title-color;
                font-family: $helvetica-regular-font-family;
                line-height: 1.3;
                margin-top: 10px;
                padding: 5px 0;
              }
              &-size {
                color: $color-light-grey-2;
                font-family: $helvetica-regular-font-family;
                margin: 0 auto 11px;
                width: 100%;
              }
            }
            .sample-box {
              height: auto;
              margin: 0 9px 20px;
              outline: 0;
              padding-bottom: 35px;
              position: relative;
              .button-container {
                display: block;
                label {
                  display: none;
                }
                .sample-select-msg {
                  #{$ldirection}: 0;
                  #{$rdirection}: 0;
                  border: 2px solid $cr19-border-light;
                  font-family: $helvetica-bold-font-family;
                  font-size: 15px;
                  margin: 0 auto;
                  text-align: center;
                  text-transform: capitalize;
                  width: 118px;
                }
              }
              &.selected {
                .sample-select-msg {
                  background-color: $color-black;
                  border-color: $color-black;
                  color: $color-white;
                }
              }
              &.disabled {
                .sample-select-msg {
                  color: $color-gray-dusty;
                  pointer-events: none;
                }
              }
            }
            .tooltip {
              #{$rdirection}: 0;
              display: inline-block;
              position: absolute;
              top: 0;
              width: 100%;
              &__info-icon {
                #{$rdirection}: 10px;
                border-radius: 50%;
                color: $color-white;
                cursor: pointer;
                height: 15px;
                line-height: 1.5;
                position: absolute;
                top: 10px;
                width: 15px;
                &::before {
                  #{$rdirection}: 0;
                  background: url('/media/images/cart_enhancement/information.svg') no-repeat center;
                  content: '';
                  display: inline-block;
                  height: 15px;
                  position: absolute;
                  width: 15px;
                }
              }
              &-text {
                #{$rdirection}: 0;
                background-color: $color-white;
                border: 1px solid $color-cl-grey-dark;
                border-radius: 0;
                box-sizing: border-box;
                color: $color-cl-grey-darker;
                font-family: $helvetica-regular-font-family;
                font-size: 12px;
                letter-spacing: normal;
                line-height: 1.4;
                padding: 10px;
                position: absolute;
                text-align: center;
                top: 34px;
                width: 100%;
                z-index: 1;
              }
              &__up-arrow-icon {
                #{$rdirection}: 5px;
                bottom: 100%;
                height: 12px;
                overflow: hidden;
                position: absolute;
                width: 24px;
                &::after {
                  #{$ldirection}: 50%;
                  background-color: $color-white;
                  border: 1px solid $color-cl-grey-dark;
                  content: '';
                  height: 12px;
                  position: absolute;
                  transform: translate(-50%, 50%) rotate(45deg);
                  width: 12px;
                }
              }
            }
            .button-container {
              bottom: 0;
              display: block;
              position: absolute;
              width: 100%;
            }
            .sample-select-msg {
              border: 1px solid $color-grey;
              font-family: $font-arial;
              font-size: 15px;
              font-weight: 500;
              height: 34px;
              letter-spacing: normal;
              line-height: 1.6;
              padding: 4px 0;
              text-align: center;
              width: 118px;
              .btn_grey {
                color: $pick_x_title-color;
              }
              .btn_checkout {
                color: $pick_cyber_title-color;
              }
            }
            .sample-section-container {
              margin: 0;
              padding: 13px 16px 20px;
            }
            .samples-panel-footnote {
              color: $color-light-grey-2;
              font-size: 12px;
              line-height: 1.34;
              margin: 15px 0 7px;
            }
            .dynamic_samples-heading {
              padding: 0;
            }
            .dyn-select_sample {
              font-size: 14px;
              line-height: 1.4;
              margin-top: 8px;
            }
            .dyn-offer_text {
              color: $color-light-grey-2;
              font-size: 14px;
              font-weight: normal;
              line-height: 1.4;
              margin: 6px 0 21px;
            }
            .slides {
              box-sizing: content-box;
              display: flex;
              margin: 21px 21px 0;
              overflow: hidden;
              padding: 0 0 12px;
              width: calc(100% - 48px);
              &.slick-initialized {
                overflow: visible;
              }
              .slick-list {
                width: 100%;
              }
              .slick-track {
                display: flex;
              }
            }
            .slick-dots {
              #{$ldirection}: 0;
              bottom: 0;
              line-height: 0;
              margin: 0;
              position: absolute;
              li {
                float: none;
                height: 12px;
                margin: 0 8px;
                padding: 0;
                width: 12px;
              }
              button {
                height: 12px;
                padding: 0;
                width: 12px;
                &::before {
                  font-size: 10px;
                  height: 12px;
                  line-height: 1.2;
                  width: 12px;
                }
              }
            }
            .slick-arrow {
              &::before,
              &::after {
                display: none;
              }
            }
            .slick-prev {
              #{$ldirection}: -25px;
              background: url('/media/images/checkout/arrow-previous-gray.png') no-repeat center;
            }
            .slick-next {
              #{$rdirection}: -25px;
              background: url('/media/images/checkout/arrow-next-gray.png') no-repeat center;
            }
            .slick-prev,
            .slick-next {
              background-size: contain;
              height: 24px;
              margin-top: -35px;
              top: 43%;
              width: 24px;
            }
          }
          .cart-header {
            color: $color-cl-light-black;
            font-family: $helvetica-bold-font-family;
            font-size: 18px;
            font-weight: 500;
            height: 24px;
            letter-spacing: 0.16px;
            line-height: 1.3;
            margin-bottom: 10px;
          }
          .cart-items {
            border: solid $cr19-border-light;
            border-width: 1px 0;
            margin: 16px 0;
            padding: 0 16px;
          }
          .cart-item-rows {
            margin: 0;
            padding: 10px 0 16px;
            &.viewcart_item_custom_kit {
              .thumb {
                background-color: $color-white;
                width: calc(25% - 15px);
                .edit_kit {
                  #{$ldirection}: 0;
                  color: $color-darkest-grey;
                  display: block;
                  float: none;
                  font-size: 13px;
                  font-weight: 700;
                  width: 100%;
                }
              }
              .prod-info {
                width: 70%;
              }
            }
            .thumb {
              margin-#{$rdirection}: 15px;
              padding-top: 6px;
              width: 80px;
            }
            .qty {
              margin: 5px 0 10px;
              width: 100%;
              &-label {
                color: $color-black;
              }
            }
            .prod-info {
              margin-bottom: 13px;
              width: calc(100% - 95px);
              .prod-desc {
                .item-remove {
                  #{$rdirection}: 0;
                  position: absolute;
                  top: 0;
                  .remove_link {
                    background-size: 17px;
                    height: 17px;
                    width: 17px;
                  }
                }
                .cart-item__desc--divider {
                  background-color: $color-gray-tier2;
                  height: 16px;
                  margin: 0 14px;
                  width: 1px;
                }
                .prod-details {
                  margin-bottom: 10px;
                  width: 90%;
                  .prod-name {
                    margin-bottom: 0;
                    width: 90%;
                    a {
                      color: $color-darkest-grey;
                      font-family: $helvetica-regular-font-family;
                      font-size: 14px;
                      font-weight: normal;
                    }
                  }
                  .prod-size-color-qty {
                    display: flex;
                    flex-wrap: wrap;
                    margin: 8px 0 0;
                    .color {
                      align-items: center;
                      display: flex;
                      margin: 0;
                    }
                    .shade-container {
                      height: 16px;
                      margin-#{$rdirection}: 8px;
                      width: 16px;
                    }
                    .size,
                    .shade-name {
                      color: $color-light-grey-2;
                      font-family: $helvetica-regular-font-family;
                      font-size: 14px;
                      line-height: 1.4;
                    }
                  }
                }
                .preorder-msg {
                  margin: 5px 0 0;
                }
                .sample-desc,
                .price--mobile,
                .item-total {
                  color: $color-darkest-grey;
                  font-family: $helvetica-bold-font-family;
                  font-size: 14px;
                  font-weight: bold;
                  letter-spacing: normal;
                  line-height: 1.4;
                  width: 31%;
                }
                .sample-desc {
                  margin-top: 0;
                  width: 68%;
                }
                .price--mobile,
                .item-total {
                  .highlighted-price {
                    color: $color-darkest-grey;
                    font-family: $helvetica-bold-font-family;
                    font-size: 14px;
                    font-weight: bold;
                    letter-spacing: 0;
                    line-height: 1.4;
                  }
                }
                .price--mobile {
                  display: flex;
                  padding-#{$rdirection}: 10px;
                  width: 69%;
                  .strike-thru-price {
                    color: $color-light-grey-2;
                    font-family: $helvetica-regular-font-family;
                    font-weight: normal;
                    letter-spacing: 0;
                    line-height: 1.4;
                    margin-#{$rdirection}: 15px;
                    text-decoration: line-through;
                  }
                }
                .promo-messaging {
                  margin: 0 0 10px;
                  font-size: 12px;
                  font-weight: normal;
                }
              }
              .size {
                font-size: 14px;
                line-height: 1.43;
              }
            }
            .cart-item__donations-description {
              margin-bottom: 0;
              .qty-donation {
                margin: 15px 0 0;
                .qty-container {
                  .cart-qty-minus,
                  .cart-qty-plus {
                    height: 32px;
                    width: 32px;
                  }
                  .qty-field {
                    font-size: 14px;
                    font-weight: 600;
                    height: 32px;
                    width: 55px;
                  }
                  .icon--minus,
                  .icon--plus {
                    background-position: center;
                    background-size: 13px;
                    height: 24px;
                    width: 24px;
                  }
                  .icon--minus {
                    background-position: center 7px;
                  }
                }
              }
            }
            .sub-cart-item-rows {
              margin-#{$ldirection}: 95px;
              width: 71%;
              .qty {
                padding: 0;
              }
              .promo-messaging {
                margin: 0 0 10px;
                font-size: 12px;
                font-weight: normal;
              }
              .refill-select-container {
                font-size: 14px;
                margin-bottom: 0;
                width: 100%;
                &::after {
                  #{$rdirection}: 17px;
                  border-bottom: 0;
                  border: solid transparent;
                  border-top-color: $color-cl-light-black;
                  border-width: 7px 5px 0;
                  content: '';
                  top: 20px;
                }
                .refill_select {
                  @include swap_direction(padding, 10px 61px 10px 16px);
                  border-radius: 0;
                  color: $color-darkest-grey;
                  font-family: $helvetica-regular-font-family;
                  font-size: 15px;
                  height: 44px;
                  text-overflow: ellipsis;
                }
              }
              .replenishment-notice {
                color: $color-black;
                font-size: 12px;
                letter-spacing: 0;
                line-height: 1.6;
                margin: 10px 0 0;
              }
              .replenishment-form {
                display: flex;
                margin: 0;
                position: relative;
                width: 100%;
                .refill-select-container {
                  margin: 0;
                }
                .replenishment__description {
                  align-items: center;
                  display: flex;
                  margin-#{$ldirection}: 30px;
                }
              }
            }
            .qty {
              .qty-container {
                .cart-qty-minus,
                .cart-qty-plus,
                .qty-field {
                  appearance: none;
                  background-color: $color-white;
                  border-radius: 0;
                  box-shadow: none;
                  color: $color-darkest-grey;
                  float: $ldirection;
                  font-family: $helvetica-regular-font-family;
                  font-size: 11px;
                  font-weight: normal;
                  height: 25px;
                  line-height: 1.8;
                  outline: none;
                  padding: 0;
                  text-align: center;
                  width: 25px;
                  -moz-appearance: none;
                  -webkit-appearance: none;
                }
                .cart-qty-minus,
                .cart-qty-plus {
                  border: 1px solid $color_celeste_approx;
                  opacity: 1;
                }
                .cart-qty-minus:disabled,
                .cart-qty-plus:disabled {
                  cursor: default;
                  .icon {
                    opacity: 0.3;
                  }
                }
                .qty-field {
                  border: solid $color_celeste_approx;
                  border-width: 1px 0;
                  padding: 2px;
                }
                .icon {
                  &--plus {
                    background: url('/media/images/icons/plus-icon.png') no-repeat 0 0 transparent;
                  }
                  &--minus {
                    background: url('/media/images/icons/minus-icon.png') no-repeat 0 0 transparent;
                  }
                  &--plus,
                  &--minus {
                    background-position: 1px 7px;
                    background-size: 10px;
                    display: block;
                    height: 100%;
                    margin: 0 auto;
                    width: 12px;
                  }
                }
              }
            }
            &:last-child {
              border-bottom: 0;
            }
          }
          .sticky-checkout-overlay {
            .payment-logo-container {
              display: flex;
              justify-content: center;
              padding-top: 10px;
              width: 100%;
              &__item {
                height: 16.5px;
                margin-#{$rdirection}: 13px;
                width: 24px;
                &--visa {
                  background: url('/media/images/checkout/visa.png') no-repeat center;
                }
                &--master-card {
                  background: url('/media/images/checkout/mastercard.png') no-repeat center;
                }
                &--maestro-card {
                  background: url('/media/images/checkout/maestro.png') no-repeat center;
                }
                &--discover {
                  background: url('/media/images/checkout/discover.png') no-repeat center;
                }
                &--american-express {
                  background: url('/media/images/checkout/ae.png') no-repeat center;
                }
                &--afterpay {
                  background: url('/media/images/checkout/afterpay_logo.png') no-repeat center;
                  width: 58px;
                }
                &--visa,
                &--master-card,
                &--maestro-card,
                &--discover,
                &--american-express,
                &--afterpay {
                  background-size: 100%;
                }
              }
              &__item:last-child {
                margin-#{$rdirection}: 0;
              }
            }
          }
          .sticky-checkout-button {
            border: 0;
            box-shadow: 0 -4px 8px 0 $color-black-2opacity;
            padding: 13px 16px 16px;
            .checkout-button {
              letter-spacing: 0;
              line-height: 24px;
              margin-top: 0;
            }
            .order-summary {
              &__total-content {
                margin-bottom: 12px;
              }
              &__total-label,
              &__total-value {
                color: $pick_x_title-color;
                font-family: $helvetica-bold-font-family;
                line-height: 1.1;
              }
              &__total-label {
                float: $ldirection;
                font-weight: bold;
              }
              &__total-value {
                font-weight: normal;
                text-align: $rdirection;
              }
            }
          }
          .checkout-table {
            .viewcart-bag-count {
              @include swap_direction(margin, 14px 0 1px 16px);
              color: $color-cl-light-black;
              font-family: $helvetica-bold-font-family;
              font-size: 18px;
              font-weight: bold;
              line-height: 1.7;
              padding: 0;
            }
            &__info {
              border: 0;
              color: $color-darkest-grey;
              font-family: $helvetica-regular-font-family;
              font-size: 14px;
              font-weight: normal;
              line-height: 1.4;
              margin: 0 16px 20px;
              padding: 0;
            }
            .checkout-header-signin {
              margin-bottom: 16px;
              &-hdr {
                align-items: center;
                display: flex;
                font-family: $helvetica-regular-font-family;
                font-size: 13px;
                font-weight: bold;
                height: 50px;
                letter-spacing: 0;
              }
              &-text {
                letter-spacing: 0;
                line-height: 1.5;
                text-decoration: underline;
              }
              &-content-link {
                font-weight: bold;
              }
              .icon {
                @include swap_direction(margin, 0 0 0 5px);
                background: none;
                font-size: 16px;
                height: 14px;
                line-height: 1;
                position: static;
                width: auto;
              }
            }
          }
          .guarantee-section {
            padding: 17px 16px 15px;
            &__description {
              color: $color-btn-dark-gray;
              font-family: $helvetica-regular-font-family;
              font-size: 14px;
              font-weight: normal;
              letter-spacing: 0.2px;
              line-height: 1.4;
              margin-top: 5px;
            }
          }
          .gift-options-wrapper {
            border: solid $cr19-border-light;
            border-width: 1px 0;
            margin: 0 0 16px;
            padding: 13px 16px;
            .gift-options {
              &__header {
                color: $color-cl-light-black;
                font-family: $helvetica-bold-font-family;
                font-size: 16px;
                font-weight: bold;
                letter-spacing: normal;
                line-height: 1;
                .icon {
                  top: 0;
                }
              }
              &__content {
                padding-top: 17px;
                .gift__content--radio-btn-container {
                  .gift-wrap-form {
                    .gift-wrap-label {
                      &::before {
                        #{$ldirection}: 1px;
                        background-color: $color-light-grey;
                        border-radius: 7px;
                        height: 14px;
                        top: 3px;
                        width: 34px;
                      }
                      &::after {
                        border: 1px solid $color-lighter-grey;
                        box-shadow: none;
                        top: 1px;
                      }
                    }
                    .is-gift-input:checked ~ .gift-wrap-label::before {
                      background-color: $color-black;
                    }
                    .is-gift-input:checked ~ .gift-wrap-label::after {
                      #{$ldirection}: 17px;
                      box-shadow: 0 1px 5px $color-cl-medium-darker;
                    }
                    .gift-form-container__title {
                      display: none;
                    }
                    .is-gift-input:checked ~ .gift-form-container__title {
                      display: block;
                    }
                    .gift-yes,
                    .gift-no {
                      font-weight: normal;
                    }
                    .gift-no {
                      color: $color-black;
                    }
                    .gift-success {
                      &__message {
                        &--personal-text,
                        &--text,
                        &--edit {
                          color: $color-darkest-grey;
                          font-family: $helvetica-regular-font-family;
                          font-size: 14px;
                          font-weight: normal;
                          letter-spacing: 0;
                          line-height: 1.4;
                        }
                        &--personal-text {
                          margin-#{$rdirection}: 5px;
                        }
                        &--edit {
                          cursor: pointer;
                          font-size: 13px;
                          font-weight: bold;
                          letter-spacing: 1.25px;
                          text-decoration: underline;
                          text-transform: capitalize;
                        }
                        &--divider {
                          background-color: $color-gray-tier2;
                          display: inline-block;
                          height: 20px;
                          margin: 0 10px;
                          vertical-align: middle;
                          width: 1px;
                        }
                        &--text {
                          word-break: break-all;
                        }
                      }
                    }
                    .gift-form-container {
                      &__textarea {
                        .message-field {
                          appearance: none;
                          border: 1px solid $color-black-32opacity;
                          border-radius: 0;
                          height: 92px;
                          outline: none;
                          padding: 15px;
                          -moz-appearance: none;
                          -webkit-appearance: none;
                          &.label-mode + .gift-wrap-label {
                            #{$ldirection}: 13px;
                            top: -10px;
                          }
                        }
                        .gift-wrap-label {
                          #{$ldirection}: 15px;
                          padding: 0 4px;
                          top: 12px;
                        }
                        .label-content {
                          font-size: 12px;
                          letter-spacing: 0;
                          line-height: 1;
                        }
                      }
                      &__title {
                        margin: 5px 0 0;
                      }
                      &__max-char {
                        color: $color-black;
                        font-family: $helvetica-regular-font-family;
                        font-size: 12px;
                        letter-spacing: 0;
                        line-height: 1.3;
                        margin: 7px 0 12px;
                      }
                      &__submit {
                        &--btn {
                          background: $color-white;
                          border: 2px solid $color-grey;
                          border-radius: 0;
                          color: $color-cl-darker-grey;
                          float: none;
                          font-size: 15px;
                          font-weight: bold;
                          height: 45px;
                          line-height: 1.6;
                          margin-top: 6px;
                          padding: 0;
                          width: 140px;
                        }
                      }
                    }
                  }
                }
              }
            }
            .gift {
              &__img {
                @include swap_direction(margin, 0 15px 0 0);
                width: 70px;
              }
              &__content {
                &--title {
                  margin: 0;
                }
                &--radio-btn-container {
                  margin-top: 14px;
                  #gift-form-container {
                    float: none;
                    margin-top: 8px;
                  }
                }
              }
              &-form-container {
                margin-top: 17px;
              }
            }
          }
          .donations-wrapper {
            border: solid $cr19-border-light;
            border-width: 1px 0;
            margin: 0 0 16px;
            padding: 12px 16px;
            .donations-header {
              color: $color-cl-darker-grey;
              font-family: $helvetica-bold-font-family;
              font-size: 16px;
              font-weight: bold;
              letter-spacing: 0;
              line-height: 1.13;
              text-transform: capitalize;
              .icon {
                top: 0;
              }
            }
            .donations-content {
              margin: 0;
              padding: 0;
              .multi_donation {
                margin: 0;
                &_img_container {
                  border: 1px solid $color-gray-tier4;
                  border-radius: 6px;
                  float: none;
                  height: 70px;
                  margin: 14px 0 11px;
                  padding: 10px;
                  width: 124px;
                  .left.donation-image {
                    float: none;
                    height: 100%;
                    width: 100%;
                  }
                }
                .donation-title,
                .donation-description,
                .bcrf-info-link {
                  color: $color-black;
                  font-size: 14px;
                  letter-spacing: 0;
                  line-height: 1.43;
                  margin: 0 0 8px;
                }
                .donation-title,
                .bcrf-info-link {
                  font-weight: normal;
                }
                .bcrf-info-link {
                  margin: 8px 0 15px;
                  text-decoration: underline;
                  text-transform: capitalize;
                }
                .bca_donation {
                  display: flex;
                  flex-wrap: wrap;
                  margin: 0;
                  .form-item {
                    align-items: center;
                    display: flex;
                    .field {
                      border: 1px solid $color-black-54opacity;
                      height: 20px;
                      margin: 0;
                      padding: 2px;
                      width: 20px;
                      &:checked {
                        border-color: $color-black;
                      }
                    }
                    label {
                      letter-spacing: 0;
                      padding-#{$ldirection}: 8px;
                    }
                  }
                }
                .email_promotions {
                  margin: 0;
                }
                .donation-submit__cta {
                  margin: 0 0 9px;
                  .promo_bca_button {
                    background: $color-white;
                    border: 2px solid $color-grey;
                    border-radius: 0;
                    color: $color-cl-darker-grey;
                    float: none;
                    font-size: 15px;
                    font-weight: bold;
                    height: 45px;
                    margin-top: 14px;
                    text-transform: inherit;
                    width: 140px;
                  }
                }
              }
            }
          }
          .section-header {
            color: $color-cl-light-black;
            font-family: $helvetica-bold-font-family;
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 0.19px;
            line-height: 2;
          }
          .section-content {
            color: $color-darkest-grey;
            font-family: $helvetica-regular-font-family;
            font-size: 14px;
            font-weight: normal;
            letter-spacing: 0;
            line-height: 1.4;
            margin-top: 8px;
          }
          .button {
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 1px;
            margin-top: 16px;
          }
          .empty-cart {
            &-message {
              font-family: $helvetica-regular-font-family;
            }
            p {
              font-size: 14px;
              font-weight: normal;
              letter-spacing: normal;
              text-align: $ldirection;
            }
            .guarantee-section--empty-cart {
              .guarantee-section {
                &__title {
                  color: $color-cl-light-black;
                  font-family: $helvetica-regular-font-family;
                  font-size: 14px;
                  font-weight: bold;
                  letter-spacing: 0.25px;
                  line-height: 1.4;
                  padding-top: 17px;
                  text-align: $ldirection;
                  text-transform: capitalize;
                }
                &__content {
                  color: $color-btn-dark-gray;
                  font-weight: 500;
                  text-transform: none;
                }
              }
            }
          }
          .section-box {
            border: 0;
            margin: 18px 0 0;
            padding: 0;
            .need_help__content,
            .need-help__link {
              color: $color-black;
              font-family: $helvetica-regular-font-family;
              letter-spacing: 0.25px;
              text-align: $ldirection;
              .need-help__link {
                text-decoration: underline;
              }
            }
          }
          .viewcart-shortcuts-panel {
            height: auto;
            line-height: normal;
            padding-bottom: 16px;
            .shortcuts-content-list {
              &-item {
                width: 100%;
              }
              &-link {
                color: $color-black;
                font-family: $helvetica-regular-font-family;
                font-size: 14px;
                padding: 0;
              }
              &__right-arrow {
                color: $color-black;
                font-size: 14px;
                font-weight: bold;
              }
            }
          }
        }
        #sticky-checkout-button {
          .checkout-button {
            align-items: center;
            display: flex;
            height: 50px;
            justify-content: center;
            .lock-icon {
              margin-#{$rdirection}: 4px;
              width: 23px;
            }
          }
        }
      }
    }
  }
  .generic-helplinks-overlay {
    #{$ldirection}: 0;
    width: 100% !important;
    .block-nodeblock {
      .outer-wrap {
        padding: 0;
      }
    }
  }
  .replenishment__description {
    .overlay-link {
      #{$rdirection}: 0;
      border-radius: 50%;
      color: $color-white;
      display: inline-block;
      font-size: 10px;
      height: 15px;
      line-height: 1.6;
      position: absolute;
      text-align: center;
      text-decoration: none;
      text-transform: lowercase;
      width: 15px;
      &::before {
        #{$rdirection}: 0;
        background: url('/media/images/cart_enhancement/information.svg') no-repeat center;
        content: '';
        display: inline-block;
        height: 15px;
        position: absolute;
        width: 15px;
      }
    }
  }
  .customer-service {
    .sidebar-page__content {
      font-size: 20px;
      line-height: 1.4;
      margin: 0 0 10px;
      text-align: center;
    }
  }
  .replenishment-notice-info {
    letter-spacing: 0.25px;
    line-height: 1.5;
  }
  #foreground-node {
    .close-container a {
      background: url('/media/images/icons/plus-icon.png') no-repeat 0 0 transparent;
      background-size: 16px;
      height: 16px;
      transform: rotate(45deg);
      width: 16px;
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
    }
  }
  @if $enable-restrict-ingredient-error == true {
    .restricted-ingredient {
      background: url('#{$base-theme-path}img/icons/src/red-triangle.svg') no-repeat;
      background-size: 20px;
      margin-top: 2px;
    }
    .restricted-ingredient-error {
      color: $color-error-msg;
      font-size: 14px;
      margin-inline-start: 32px;
      width: 176px;
    }
  }
}
