$color-black: #000;

#content {
  .appt-book {
    .appt-book-page-header {
      &-content {
        padding-top: 14%;
        width: 91%;
        .inner {
          padding-top: 6px;
          h4 {
            .button {
              display: none;
            }
          }
        }
        h3 {
          color: $color-black;
        }
      }
    }
    .appointments-container {
      .book-appt-container {
        input {
          height: 30px !important;
        }
      }
    }
  }
  .service-select {
    .service {
      height: auto !important;
      .selection-bar {
        .selection-selector.priced {
          height: 64px;
          padding: 20px 0;
        }
        .selection-data {
          .selection-time,
          .selection-price {
            display: inline-block;
            width: 100%;
            &:empty {
              display: none !important;
            }
          }
        }
      }
    }
  }
  .confirm-container {
    .total-time {
      &.priced {
        width: 55% !important;
        padding-top: 16px;
      }
    }
    .appt-book-book-submit {
      &.priced {
        width: 45% !important;
      }
    }
  }
  .appointments-container {
    .service {
      .service-head {
        font-size: 14px;
      }
    }
  }
  .appointment-select {
    .appointments-container {
      .confirm-container {
        .start-over {
          width: 70%;
        }
      }
    }
  }
}

.appointments-page.account__section {
  .appt-book {
    .appt-book-page-header {
      &-content__title {
        @include breakpoint($ab-small-down) {
          padding-top: 22%;
          width: 96%;
          h2 {
            font-size: 14px;
          }
          h4 {
            font-size: 12px;
          }
        }
      }
    }
  }
}

.device-mobile {
  .store-locator-mobile-v1 {
    .door_row {
      .store-locator_book-appointment {
        height: 45px;
        line-height: 1.8em;
        span {
          margin: 0;
        }
      }
    }
  }
}

#appointments {
  #appt-book-my-appointments-overlay {
    .overlay-content-container {
      #appt-canceled {
        .book-new-btn {
          font-size: 12px;
        }
      }
    }
  }
}
