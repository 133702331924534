%english-font {
  font-family: 'HelveticaNeueLTStd45', Arial, Sans-Serif !important;
}
%font-medium {
  font-family: 'HiraKakuProN-W3', 'Roboto, Droid Sans' !important;
}

body,
td,
th,
p,
a,
div,
span,
input,
select,
button,
textarea,
#navigation,
#footer {
  @extend %font-medium;
}

h1,
h2,
h3,
h4,
h5,
h6,
ul,
li {
  @extend %font-medium;
}

.top-left,
.top-right,
a.button,
strong,
b,
form label,
fieldset span,
a.btn-add-to-bag {
  @extend %font-medium;
}

.gnav-block {
  .text-link--style-2-bold,
  a.text-link--style-2-bold,
  .header-gnav-section__panel-link--header span {
    font-weight: 700;
  }
}
