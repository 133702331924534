#viewcart {
  .enhanced-viewcart-page {
    .main_container {
      .multi#main {
        #content {
          .side-bar {
            background-color: $color-white;
            #order-status {
              border: solid $cr19-border-light;
              border-width: 1px 0;
              margin: 0;
              padding: 10px 16px 23px;
              .order-summary-panel__header {
                color: $color-cl-darker-grey;
                font-family: $helvetica-bold-font-family;
                font-size: 16px;
                font-weight: bold;
                line-height: 2;
                margin-bottom: 7px;
                text-transform: capitalize;
              }
              .order-summary-panel__content {
                #status-table {
                  .order-summary {
                    &__label,
                    &__value,
                    &__total-label,
                    &__total-value {
                      font-size: 14px;
                      line-height: 1.4;
                    }
                    &__label {
                      color: $cr19-text-grey;
                      font-family: $helvetica-regular-font-family;
                      letter-spacing: 0.28px;
                      .retail-info {
                        position: relative;
                      }
                      .terms-tooltip {
                        #{$ldirection}: 15%;
                        &__info-icon {
                          #{$rdirection}: 0;
                        }
                        &__text {
                          #{$ldirection}: 8%;
                        }
                      }
                    }
                    &__value {
                      color: $color-black;
                      font-family: $helvetica-regular-font-family;
                      letter-spacing: 0.34px;
                    }
                    &__total-label,
                    &__total-value {
                      color: $color-black;
                      font-family: $helvetica-bold-font-family;
                      font-weight: bold;
                      letter-spacing: 0.28px;
                    }
                    .shipping-method {
                      margin: 6px 0 9px;
                      &__select {
                        @include swap_direction(padding, 11px 61px 10px 16px);
                        border-radius: 0;
                        color: $color-darkest-grey;
                        font-family: $helvetica-regular-font-family;
                        font-size: 17px;
                        font-weight: normal;
                        height: 44px;
                        line-height: 1.2;
                      }
                      &::after {
                        #{$rdirection}: 17px;
                        border: solid transparent;
                        border-width: 7px 5px 0;
                        border-top-color: $color-cl-light-black;
                        content: '';
                        top: 20px;
                      }
                    }
                  }
                }
                .order-summary__content.afterpay {
                  color: $color-black;
                  margin: 0;
                  padding-top: 6px;
                }
                .pre_order_text {
                  margin: 0;
                }
                .afterpay,
                .afterpay-messages-container {
                  font-size: 12px;
                  font-weight: normal;
                  line-height: 1.34;
                  margin-top: 8px;
                  padding-#{$rdirection}: 25px;
                  position: relative;
                  width: 100%;
                  .ap-logo {
                    height: 16px;
                    margin: 0 8px;
                    vertical-align: middle;
                    width: 80px;
                  }
                  .afterpay-logo {
                    vertical-align: middle;
                    width: 80px;
                  }
                  .afterpay-learnmore {
                    #{$rdirection}: 0;
                    position: absolute;
                    top: 6px;
                    &__icon {
                      background-color: $color-white;
                      height: 15px;
                      line-height: 1.6;
                      position: relative;
                      width: 15px;
                      &::before {
                        #{$rdirection}: 0;
                        background: url(/sites/clinique/themes/cl_base/img/icons/src/information.svg)
                          no-repeat center;
                        content: '';
                        display: inline-block;
                        height: 15px;
                        position: absolute;
                        width: 15px;
                      }
                    }
                  }
                }
              }
            }
            .need_help#need-help {
              border-bottom: 1px solid $cr19-border-light;
              padding: 0;
              .section-box {
                border: 0;
                margin: 0 16px;
                padding: 18px 0 35px;
                .review_header,
                .need-help__link {
                  color: $color-black;
                  font-family: $helvetica-regular-font-family;
                  font-size: 14px;
                  letter-spacing: 0.25px;
                  line-height: 1.4;
                }
                .review_header {
                  border: 0;
                  margin: 0;
                }
                .need-help__link {
                  text-decoration: underline;
                }
              }
            }
            .left_col.offer_code {
              .offer_code_box#offer-code-box {
                border-bottom: 1px solid $cr19-border-light;
                border-top: 0;
                padding: 11px 17px 21px;
                .error_messages {
                  margin: 0;
                }
                .offer-code {
                  &__message {
                    @include swap_direction(padding, 0 0 0 32px);
                    color: $color-error-msg;
                    font-family: $helvetica-regular-font-family;
                    font-size: 14px;
                    line-height: 1.4;
                    margin: 0 0 17px;
                    position: relative;
                    .message-icon {
                      #{$ldirection}: 0;
                      background: url('/media/images/cart_enhancement/error-icon.png') no-repeat
                        center;
                      background-size: 16px;
                      height: 16px;
                      position: absolute;
                      top: 2px;
                      width: 16px;
                    }
                  }
                  &__byline {
                    .deferred,
                    .success {
                      .message-icon {
                        height: 20px;
                        top: 0;
                        width: 20px;
                      }
                    }
                    .deferred {
                      color: $color-alert-msg;
                      a {
                        color: $color-alert-msg;
                      }
                      .message-icon {
                        background: url('/media/images/cart_enhancement/attention-icon.png')
                          no-repeat center;
                        background-size: 20px;
                      }
                    }
                    .success {
                      color: $color-success-msg;
                      .message-icon {
                        background: url('/media/images/cart_enhancement/success-icon.png') no-repeat
                          center;
                        background-size: 20px;
                      }
                    }
                  }
                  &__success {
                    color: $color-success-msg;
                    min-height: 20px;
                    margin-bottom: 12px;
                    padding-#{$ldirection}: 32px;
                    position: relative;
                    .message-icon {
                      #{$ldirection}: 0;
                      background: url('/media/images/cart_enhancement/success-icon.png') no-repeat
                        center;
                      background-size: 20px;
                      height: 20px;
                      position: absolute;
                      top: 0;
                      width: 20px;
                    }
                    &--text,
                    &--edit {
                      font-family: $helvetica-regular-font-family;
                      font-size: 14px;
                      line-height: 1.4;
                    }
                    &--edit {
                      #{$rdirection}: 0;
                      color: $color-black;
                      cursor: pointer;
                      font-weight: bold;
                      position: absolute;
                      text-decoration: underline;
                      text-transform: uppercase;
                    }
                  }
                  &__content {
                    .form_element {
                      line-height: 0;
                      margin: 0;
                      padding-top: 10px;
                      label {
                        background-color: $color-white;
                        color: $color-black-88opacity;
                        font-size: 15px;
                        font-weight: bold;
                        letter-spacing: 0;
                        top: 24px;
                      }
                      .label-mode + label {
                        #{$ldirection}: 15px;
                        top: -4px;
                      }
                      .offer-code {
                        &-field {
                          @include swap_direction(margin, 0 8px 0 0);
                          display: inline-block;
                          height: 54px;
                          position: relative;
                          width: 52%;
                          .label-content {
                            text-transform: capitalize;
                          }
                        }
                        &__apply-btn,
                        &__box {
                          background-color: $color-white;
                          border: 1px solid $color-grey;
                          border-radius: 0;
                        }
                        &__apply-btn {
                          color: $lightBlack_color;
                          float: $rdirection;
                          height: 56px;
                          margin: 0;
                          padding: 10px 0;
                          width: 45%;
                        }
                        &__box {
                          box-shadow: none;
                          color: $color-black-87opacity;
                          float: none;
                          font-family: $helvetica-regular-font-family;
                          font-size: 17px;
                          line-height: 1.4;
                          padding: 0 20px;
                          -webkit-box-shadow: none;
                        }
                      }
                      .one-offer-only,
                      .see-all-offers {
                        display: none;
                      }
                    }
                  }
                }
              }
              .section-loyalty-offers {
                .section-content {
                  border-bottom: 1px solid $cr19-border-light;
                  margin-top: 0;
                }
                .accordion-wrapper,
                .accordion-wrapper__content {
                  border: 0;
                  padding: 0;
                }
                .accordion-wrapper {
                  margin: 0 16px;
                  .section-header {
                    color: $color-cl-darker-grey;
                    font-family: $helvetica-bold-font-family;
                    font-size: 16px;
                    font-weight: bold;
                    letter-spacing: 0.23px;
                    line-height: 2;
                    margin: 0;
                    padding: 4px 0 8px;
                  }
                  .offer-code-wallet {
                    &__content {
                      align-items: flex-start;
                      display: flex;
                    }
                    &__code-info--applied {
                      @include swap_direction(margin, 0 15px 13px 0);
                      display: inline-block;
                      height: 90px;
                      min-height: auto;
                      width: 90px;
                    }
                    &__image {
                      width: 100%;
                    }
                    &__description {
                      flex: 1;
                    }
                    &__item {
                      background: $pick_cyber_title-color;
                      border: 1px solid $color-grey;
                      height: 156px;
                      padding: 16px;
                      .offer_title {
                        margin: 10px 0;
                        text-align: #{$ldirection};
                        font-size: 15px;
                      }
                      .offer_desc {
                        color: $color-black-88opacity;
                        font-size: 12px;
                        font-weight: 500;
                        letter-spacing: 0.4px;
                        line-height: 1.34;
                        margin-bottom: 4px;
                        text-align: $ldirection;
                      }
                      .more_link {
                        color: $color-black-64opacity;
                        font-size: 12px;
                        margin: 0;
                      }
                    }
                    .offer_apply {
                      margin: 0;
                      .btn-shopnow {
                        background-color: $color-white;
                        border: 2px solid $color-grey;
                        border-radius: 0;
                        color: $color-cl-darker-grey;
                        font-size: 15px;
                        font-weight: bold;
                        height: 45px;
                        letter-spacing: 0;
                        line-height: 1.6;
                        margin: 0;
                        padding: 10px 16px 12px;
                        width: 100%;
                        &.not_meet,
                        &.disable_offer {
                          color: $color-grey;
                          cursor: not-allowed;
                        }
                      }
                    }
                    .flex-control-paging {
                      #{$ldirection}: 0;
                      #{$rdirection}: 0;
                      display: block;
                      height: 12px;
                      margin: 0 auto;
                      top: 85%;
                      width: 80%;
                      li {
                        height: 12px;
                        margin: 0 8px;
                        width: 12px;
                        a {
                          height: 12px;
                          width: 12px;
                        }
                      }
                    }
                    .flex-direction-nav {
                      .flex-next {
                        #{$rdirection}: -4px;
                        background: url('/media/images/cart_enhancement/arrow-right.png') center
                          no-repeat;
                        float: $rdirection;
                      }
                      .flex-prev {
                        background: url('/media/images/cart_enhancement/arrow-left.png') center
                          no-repeat;
                        float: $ldirection;
                      }
                      .flex-next,
                      .flex-prev {
                        background-size: contain;
                        height: 14px;
                        margin-top: -32px;
                        position: relative;
                        width: 20px;
                        &::before {
                          content: '';
                        }
                      }
                      .flex-disabled {
                        pointer-events: none;
                      }
                    }
                  }
                  .loyalty_points {
                    color: $color-black-64opacity;
                    font-size: 12px;
                    letter-spacing: 0.4px;
                    margin-bottom: 13px;
                    text-align: $ldirection;
                  }
                }
                .slides {
                  .flex-active-slide {
                    height: auto;
                    margin-bottom: 48px;
                  }
                }
              }
            }
          }
          #sticky-checkout-button-overlay {
            .checkout-button {
              align-items: center;
              display: flex;
              height: 50px;
              justify-content: center;
              margin-top: 0;
              .lock-icon {
                height: 24px;
                margin-#{$rdirection}: 4px;
                padding-top: 2px;
                width: 23px;
              }
            }
            .checkout-paypal-btn {
              background-position: center;
              flex: 1;
              height: 50px !important;
              letter-spacing: 0;
              line-height: 1.5;
              margin-top: 0;
            }
            .checkout-applypay-paypal-buttons {
              align-items: center;
              display: flex;
              margin-bottom: 10px;
            }
            .apple-pay-button-container {
              @include swap_direction(margin, 0 10px 0 0);
              border: 1px solid $color-btn-grey;
              flex: 1;
              height: 50px;
              .apple-pay-button {
                background-position: center;
                margin: 0;
                width: 100%;
              }
            }
          }
        }
      }
    }
    .page-footer {
      margin-bottom: 30px;
    }
  }
}
