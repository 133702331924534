.elc-vto-foundation {
  .elc-vto-foundation-shade-grid {
    .elc-vto {
      &-tab {
        font-size: 11px;
      }
      &-see-all-shades,
      &-see-perfect-shades {
        &.elc-button {
          [data-component] & {
            font-size: 12px;
          }
        }
      }
      &-perfect-shade-label {
        font-size: 10px;
        letter-spacing: normal;
        width: 75px;
      }
      &-see-pair-product {
        font-size: 13px;
      }
      &-custom-shade-picker {
        width: 100%;
        .slick-slide:nth-child(2) {
          flex-basis: 25%;
        }
      }
      &-product-name {
        font-weight: 700;
      }
      &-custom-shade-picker-item-name {
        display: inline;
      }
    }
    .elc-price-formatted-wrapper {
      .elc-price-formatted {
        font-weight: 700;
      }
    }
  }
  .elc-vto-foundation-shade-picker-wrapper {
    margin-bottom: 36px;
  }
}
